import {Search} from "@mui/icons-material";
import {InputAdornment, InputBase, Stack} from "@mui/material";

type Props = {
  backgroundColor?: string;
  width?: string;
};
export default function SearchBar({backgroundColor, width}: Props) {
  return (
    <Stack
      sx={(theme) => ({
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: backgroundColor || "transparent",
        border: "1px solid " + theme.palette.primary.light,
        borderRadius: "10px",
        width: width || "400px",
        maxWidth: {sm: "50%"},
        color: "grey.400",
      })}
    >
      <InputAdornment position="start">
        <Search fontSize="large" sx={{ml: 1, color: "grey.400"}} />
      </InputAdornment>
      <InputBase
        type="search"
        placeholder="Search"
        fullWidth
        sx={{fontSize: "large"}}
      />
    </Stack>
  );
}
