import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import {useAppSelector} from "../../redux/store";
import SearchBar from "../../components/SearchBar";
import Sort from "../../components/Sort";
import {useState} from "react";

export default function TransactionHistory() {
  const transactions = useAppSelector(
    (store) => store.accountReducer.transactions
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Box sx={{p: 3}}>
      <Toolbar sx={{px: {sm: 0}, justifyContent: "space-between"}}>
        <Typography variant="h3" fontWeight={600}>
          Transaction History
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "flex-end",
          }}
        >
          <SearchBar width="100%" />
          <Sort />
        </Box>
      </Toolbar>
      <TableContainer
        sx={{
          backgroundColor: "background.paper",
          borderRadius: "10px",
          th: {borderBottom: "none"},
          td: {
            borderBottom: "2px solid",
            borderColor: "background.default",
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Invoice #</TableCell>
              <TableCell>Date</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map(
              (trx, index) =>
                index < (page + 1) * rowsPerPage &&
                index >= page * rowsPerPage && (
                  <TableRow key={trx.id}>
                    <TableCell>#{trx.id}</TableCell>
                    <TableCell>{trx.date}</TableCell>
                    <TableCell align="right">&#x20A6;{trx.amount}</TableCell>
                    <TableCell align="center">
                      {trx.status ? (
                        <Chip
                          variant="outlined"
                          color="success"
                          label="Paid"
                          sx={(theme) => ({
                            backgroundColor: `rgb(from ${theme.palette.success.light} r g b / 0.2)`,
                            border: "none",
                            borderRadius: "2px",
                            p: "4px",
                            height: "auto",
                          })}
                        />
                      ) : (
                        <Chip
                          variant="outlined"
                          color="error"
                          label="Unpaid"
                          sx={(theme) => ({
                            backgroundColor: `rgb(from ${theme.palette.error.light} r g b / 0.2)`,
                            border: "none",
                            borderRadius: "2px",
                            p: "4px",
                            height: "auto",
                          })}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={transactions.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
