import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import PageContainer from "../components/PageContainer";
import SearchBar from "../components/SearchBar";
import {useEffect, useState} from "react";
import {
  Add,
  CheckBoxOutlined,
  Close,
  DeleteOutline,
  DriveFileRenameOutline,
  ExpandMore,
} from "@mui/icons-material";
import DeleteDialog from "../components/DeleteDialog";
import {useAppSelector} from "../redux/store";
import {CampaignType} from "../redux/smsReducer";
import Sms from "../components/Sms";
import Sort from "../components/Sort";

type TDialog = {
  open: boolean;
  campaign?: CampaignType;
};
export default function Campaigns() {
  const [editDialog, setEditDialog] = useState<TDialog>({open: false});
  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    element: any;
    label: string;
  }>({open: false, label: " ", element: null});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {campaigns} = useAppSelector((store) => store.smsReducer);
  const [selected, setSelected] = useState<{[key: number]: boolean}>({});

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSelect(index: number) {
    const state = {...selected};
    if (state[index]) {
      delete state[index];
    } else {
      state[index] = true;
    }
    setSelected(state);
  }

  function handleDelete(element: CampaignType | string[]) {
    console.log(element);
    setDeleteDialog({open: false, label: "", element: null});
  }

  return (
    <PageContainer title="Campaign">
      <Toolbar sx={{justifyContent: "space-between"}}>
        <Typography fontWeight={600} fontSize={"2rem"}>
          Campaigns
        </Typography>
        {Object.keys(selected).length < 1 ? (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "flex-end",
            }}
          >
            <SearchBar width="60%" />
            <Sort />
            <Button
              startIcon={<Add />}
              variant="contained"
              onClick={() => setEditDialog({open: true})}
            >
              Create New
            </Button>
          </Box>
        ) : (
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteOutline />}
            size="large"
            onClick={() =>
              setDeleteDialog({
                label: "campaigns",
                open: true,
                element: Object.keys(selected),
              })
            }
          >
            Delete
          </Button>
        )}
      </Toolbar>
      <Box sx={{px: 3}}>
        <Paper elevation={0} sx={{my: 3}}>
          <TableContainer>
            <Table
              sx={{
                th: {color: "grey.500", py: 3, borderBottom: "none"},
                tr: {textTransform: "capitalize"},
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Campaign</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campaigns.map(
                  (campaign, index) =>
                    index < (page + 1) * rowsPerPage &&
                    index >= page * rowsPerPage && (
                      <TableRow key={index}>
                        <TableCell
                          padding="none"
                          onClick={() => handleSelect(index)}
                        >
                          <Checkbox
                            checked={!!selected[index]}
                            onChange={() => {}}
                            sx={{color: "grey.200"}}
                            checkedIcon={<CheckBoxOutlined />}
                          />
                        </TableCell>
                        <TableCell>{campaign.title}</TableCell>
                        <TableCell>{campaign.created_at}</TableCell>
                        <TableCell>{campaign.message}</TableCell>
                        <TableCell>
                          <ButtonGroup>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                setEditDialog({open: true, campaign})
                              }
                            >
                              <DriveFileRenameOutline />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() =>
                                setDeleteDialog({
                                  open: true,
                                  element: campaign,
                                  label: `"${campaign.title} campaign"`,
                                })
                              }
                            >
                              <DeleteOutline />
                            </IconButton>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <TablePagination
          component="div"
          count={campaigns.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <EditDialog
          open={editDialog.open}
          campaign={editDialog.campaign}
          onClose={() => setEditDialog({open: false})}
        />
        <DeleteDialog
          open={deleteDialog.open}
          onClose={() => setDeleteDialog({...deleteDialog, open: false})}
          handleDelete={handleDelete}
          label={deleteDialog.label}
          element={deleteDialog.element}
        />
      </Box>
    </PageContainer>
  );
}

function EditDialog({
  open,
  onClose,
  campaign,
}: {
  open: boolean;
  onClose: () => void;
  campaign?: CampaignType;
}) {
  const [campaignData, setCampaignData] = useState<CampaignType>(
    {} as CampaignType
  );
  useEffect(() => {
    if (campaign) {
      setCampaignData(campaign);
    } else {
      setCampaignData({} as CampaignType);
    }
  }, [campaign]);

  function handleCampaignChange(obj: Partial<CampaignType>) {
    setCampaignData({...campaignData, ...obj});
  }

  return (
    <Dialog open={open} maxWidth="xl" onClose={onClose}>
      <DialogTitle position="relative" fontWeight={600} variant="h4">
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "30%",
            right: 7,
            border: "1px solid",
          }}
          size="small"
        >
          <Close />
        </IconButton>
        {campaign ? "Edit" : "New"} Campaign
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            my: 3,
            ">.MuiFormControl-root": {
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              my: 2,
              ">.MuiFormLabel-root": {fontWeight: 700},
              ".MuiInputBase-root": {
                border: "1px solid",
                borderRadius: "5px",
                borderColor: "grey.400",
                p: 1,
                minWidth: "85%",
                outline: "none",
                "*::placeholder": {color: "grey.500"},
                "&.Mui-focused": {borderColor: "primary.light"},
              },
              ">.MuiAutocomplete-root": {
                width: "85%",
                ">.MuiInputBase-root": {width: "100%"},
              },
            },
          }}
        >
          <FormControl>
            <FormLabel>Campaign Title:</FormLabel>
            <InputBase
              placeholder="Enter your campaign title"
              value={campaignData.title}
              onChange={(e) => handleCampaignChange({title: e.target.value})}
            />
          </FormControl>
          <Sms handleSmsChange={handleCampaignChange} sms={campaignData} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large">
          <span>Send&nbsp;|</span>
          <MoreMenu />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function MoreMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function scheduleLater() {}
  function scheduleRecurring() {}

  return (
    <div>
      <span onClick={handleClick} style={{display: "flex"}}>
        <ExpandMore />
      </span>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{vertical: "top", horizontal: "left"}}
      >
        <MenuItem onClick={scheduleLater}>Schedule for Later</MenuItem>
        <MenuItem onClick={scheduleRecurring}>Schedule Recurring</MenuItem>
      </Menu>
    </div>
  );
}
