import {useEffect, useState} from "react";
import {useAppSelector} from "../../redux/store";
import {
  RecurringSmsType,
  RepeatCycle,
  RepeatCycleEnd,
  weekDays,
} from "../../redux/smsReducer";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputBase,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TablePagination,
  Toolbar,
  Typography,
} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import {
  Add,
  CheckBoxOutlined,
  Close,
  DeleteOutline,
  DeleteOutlined,
  DriveFileRenameOutline,
} from "@mui/icons-material";
import Sort from "../../components/Sort";
import DeleteDialog from "../../components/DeleteDialog";
import Sms from "../../components/Sms";
import {DatePicker, DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {formatTime} from "../../helper/time";
import {ToggleDays} from "../../components/CustomComponents";

type TDialog = {
  open: boolean;
  recurring?: RecurringSmsType;
};
export default function RecurringSms() {
  const {recurringSms} = useAppSelector((store) => store.smsReducer);
  const [editDialog, setEditDialog] = useState<TDialog>({open: false});
  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    element: any;
    label: string;
  }>({open: false, label: " ", element: null});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState<{[key: number]: boolean}>({});

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSelect(index: number) {
    const state = {...selected};
    if (state[index]) {
      delete state[index];
    } else {
      state[index] = true;
    }
    setSelected(state);
  }

  function handleDelete(element: RecurringSmsType | string[]) {
    console.log(element);
    setDeleteDialog({open: false, label: "", element: null});
  }
  return (
    <>
      <Toolbar sx={{px: {sm: 0}, justifyContent: "space-between"}}>
        <div>
          <Typography
            variant="h3"
            fontSize={"1.7rem"}
            fontWeight={600}
            marginBottom={1}
          >
            Recurring Messages
          </Typography>
          <Typography variant="subtitle1">
            Here you can create new texts and edit the settings of previously
            recurring texts.
          </Typography>
        </div>

        {Object.keys(selected).length < 1 ? (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "flex-end",
            }}
          >
            <SearchBar width="60%" />
            <Sort />
            <Button
              startIcon={<Add />}
              variant="contained"
              onClick={() => setEditDialog({open: true})}
            >
              Recurring
            </Button>
          </Box>
        ) : (
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteOutlined />}
            size="large"
            onClick={() =>
              setDeleteDialog({
                label: "recurring messages",
                open: true,
                element: Object.keys(selected),
              })
            }
          >
            Delete
          </Button>
        )}
      </Toolbar>
      <List
        sx={{
          "li>.MuiBox-root": {
            position: "relative",
            borderBottom: "1px solid",
            borderColor: "grey.200",
            borderRadius: "10px",
            pb: 2,
            px: 2,
            width: "100%",
            "&:hover": {
              backgroundColor: "background.default",
              ".MuiButtonGroup-root": {display: "inline-flex"},
            },
          },
          "li .MuiButtonGroup-root": {
            position: "absolute",
            right: 15,
            top: 15,
            display: "none",
          },
        }}
      >
        {recurringSms.map(
          (recurring, index) =>
            index < (page + 1) * rowsPerPage &&
            index >= page * rowsPerPage && (
              <ListItem key={recurring.id}>
                <Checkbox
                  onClick={() => handleSelect(recurring.id)}
                  checked={!!selected[recurring.id]}
                  onChange={() => {}}
                  checkedIcon={<CheckBoxOutlined />}
                  sx={{color: "grey.200"}}
                />
                <Box>
                  <Typography
                    sx={{
                      "&>span": {color: "primary.main"},
                      fontWeight: 500,
                      fontSize: "large",
                      my: 2,
                    }}
                  >
                    <span>TO: </span>
                    {JSON.parse(recurring.to)}
                  </Typography>
                  <Typography variant="subtitle1">
                    {recurring.message}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    marginTop={2}
                    color="grey.400"
                    textAlign="right"
                  >
                    Recurring at: {recurring.startTime}, {recurring.startDate}{" "}
                  </Typography>
                  <ButtonGroup>
                    <IconButton
                      onClick={() => setEditDialog({open: true, recurring})}
                    >
                      <DriveFileRenameOutline />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() =>
                        setDeleteDialog({
                          open: true,
                          element: recurring,
                          label: `recurring message`,
                        })
                      }
                    >
                      <DeleteOutline />
                    </IconButton>
                  </ButtonGroup>
                </Box>
              </ListItem>
            )
        )}
      </List>
      <TablePagination
        component="div"
        count={recurringSms.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <EditDialog
        open={editDialog.open}
        recurring={editDialog.recurring}
        onClose={() => setEditDialog({open: false})}
      />
      <DeleteDialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({...deleteDialog, open: false})}
        handleDelete={handleDelete}
        label={deleteDialog.label}
        element={deleteDialog.element}
      />
    </>
  );
}

function EditDialog({
  open,
  onClose,
  recurring,
}: {
  open: boolean;
  onClose: () => void;
  recurring?: RecurringSmsType;
}) {
  const [recurringData, setRecurringData] = useState<RecurringSmsType>(
    {} as RecurringSmsType
  );
  useEffect(() => {
    if (recurring) {
      setRecurringData(recurring);
    } else {
      setRecurringData({} as RecurringSmsType);
    }
  }, [recurring]);

  function handleRecurringChange(obj: Partial<RecurringSmsType>) {
    setRecurringData({...recurringData, ...obj});
  }

  return (
    <Dialog open={open} maxWidth="xl" onClose={onClose}>
      <DialogTitle position="relative" fontWeight={600} variant="h4">
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "10px",
            right: 7,
            border: "1px solid",
          }}
          size="small"
        >
          <Close />
        </IconButton>
        {recurring ? "Edit" : "New"} Recurring
        <Box sx={{fontSize: "0.9rem", fontWeight: "normal", color: "grey.500"}}>
          Enter the details you wish to include in your messages. You can be
          descriptive as preferrable
        </Box>
        <Box
          sx={{
            backgroundColor: "background.default",
            fontSize: "1rem",
            p: 2,
            my: 2,
            fontWeight: 500,
          }}
        >
          Your message will be sent out every{" "}
          {recurringData.rate > 1 && recurringData.rate}{" "}
          {recurringData.cycle === RepeatCycle.Daily && "day"}
          {recurringData.cycle === RepeatCycle.Weekly && "week"}
          {recurringData.cycle === RepeatCycle.Monthly && "month"}
          {recurringData.rate > 1 && "s"}{" "}
          {recurringData.weekDays && recurringData.weekDays.length > 0 && "on "}
          {recurringData.weekDays?.map((day) => weekDays[day] + ", ")}
          at {formatTime(recurringData.startTime)} starting{" "}
          {dayjs(recurringData.startDate).format("LL")}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            my: 3,
            ">.MuiFormControl-root": {
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              my: 2,
              ">.MuiFormLabel-root": {fontWeight: 700},
              ".MuiInputBase-root": {
                border: "1px solid",
                borderRadius: "5px",
                borderColor: "grey.400",
                p: 1,
                minWidth: "85%",
                outline: "none",
                "*::placeholder": {color: "grey.500"},
                "&.Mui-focused": {borderColor: "primary.light"},
              },
              ">.MuiAutocomplete-root": {
                width: "85%",
                ">.MuiInputBase-root": {width: "100%"},
              },
            },
          }}
        >
          <Sms handleSmsChange={handleRecurringChange} sms={recurringData} />
          <Stack
            sx={{
              gap: 2,
              ".MuiInputBase-root": {
                maxWidth: "300px",
                backgroundColor: "background.default",
                p: 1,
                borderRadius: "5px",
                fontWeight: 600,
              },
            }}
          >
            <b>Schedule Recurring</b>
            <Box sx={{display: "flex", gap: 2, alignItems: "center"}}>
              <b>Repeat Every</b>
              <InputBase
                type="number"
                value={recurringData.rate || 1}
                sx={{width: 100}}
                onChange={(e) =>
                  handleRecurringChange({
                    rate: Math.abs(Number(e.target.value)),
                  })
                }
              />
              <Select
                value={recurringData.cycle || RepeatCycle.Daily}
                native
                input={<InputBase />}
                onChange={(e) =>
                  handleRecurringChange({cycle: Number(e.target.value)})
                }
              >
                {Object.values(RepeatCycle)
                  .filter((e) => typeof e === "number")
                  .map((e, i) => (
                    <option key={e} value={e}>
                      {RepeatCycle[i]}
                    </option>
                  ))}
              </Select>
              <DatePicker
                sx={{
                  fieldset: {display: "none"},
                  ".MuiInputBase-input": {p: 0},
                }}
                disablePast
                onChange={(value) =>
                  handleRecurringChange({
                    startDate: value?.format("YYYY-MM-DD") || "",
                  })
                }
                value={dayjs(recurringData.startDate)}
              />
              <InputBase
                type="time"
                onChange={(e) =>
                  handleRecurringChange({startTime: e.target.value})
                }
                value={recurringData.startTime || "00:00"}
              />
            </Box>
            {recurringData.cycle === RepeatCycle.Weekly && (
              <>
                <b>Repeat on</b>
                <ToggleDays
                  days={recurringData.weekDays || []}
                  setDays={(v) => handleRecurringChange({weekDays: v})}
                />
              </>
            )}
            <FormControl>
              <FormLabel sx={{fontWeight: "700", color: "inherit"}}>
                Ends
              </FormLabel>
              <RadioGroup value={recurringData.endType || RepeatCycleEnd.Never}>
                <FormControlLabel
                  value={0}
                  label={RepeatCycleEnd[0]}
                  control={<Radio />}
                  onChange={(e) =>
                    handleRecurringChange({
                      endType: Number((e.target as HTMLInputElement).value),
                    })
                  }
                />
                <FormControlLabel
                  value={1}
                  label={RepeatCycleEnd[1]}
                  control={<Radio />}
                  onChange={(e) =>
                    handleRecurringChange({
                      endType: Number((e.target as HTMLInputElement).value),
                    })
                  }
                />
                {recurringData.endType ===
                  RepeatCycleEnd["After Number of Occurrences"] && (
                  <InputBase
                    type="number"
                    value={
                      (typeof recurringData.endValue === "number" &&
                        recurringData.endValue) ||
                      1
                    }
                    sx={{width: 100}}
                    onChange={(e) =>
                      handleRecurringChange({
                        endValue: Math.abs(Number(e.target.value)),
                      })
                    }
                  />
                )}
                <FormControlLabel
                  value={2}
                  label={RepeatCycleEnd[2]}
                  control={<Radio />}
                  onChange={(e) =>
                    handleRecurringChange({
                      endType: Number((e.target as HTMLInputElement).value),
                    })
                  }
                />
                {recurringData.endType ===
                  RepeatCycleEnd["Custom Day/Time"] && (
                  <DateTimePicker
                    disablePast
                    value={
                      (typeof recurringData.endValue === "string" &&
                        dayjs(recurringData.endValue)) ||
                      dayjs()
                    }
                    onChange={(v) =>
                      handleRecurringChange({endValue: v?.toISOString()})
                    }
                    sx={{
                      fieldset: {display: "none"},
                      ".MuiInputBase-input": {p: 0},
                    }}
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="large" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" size="large">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
