import {CloudUpload} from "@mui/icons-material";
import {Button, FormControl, styled, SxProps, Typography} from "@mui/material";
import {useRef, useState} from "react";

// drag drop file component
export default function DragDropFile({sx}: {sx?: SxProps}) {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef<null | HTMLInputElement>(null);

  // handle drag events
  const handleDrag = function (e: React.DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: React.DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current?.click();
  };

  function handleFile(files: FileList) {
    console.log(files);
    alert("Number of files: " + files.length);
  }

  return (
    <Container
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
      sx={sx}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple
        onChange={handleChange}
        accept=".csv, .xls, .xlsx"
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        <div>
          <CloudUpload color="primary" className="icon" />
          <Typography variant="h6" fontWeight={600}>
            Drag and drop or
            <Button className="upload-button" onClick={onButtonClick}>
              browse
            </Button>
            your files
          </Typography>
          <Typography variant="subtitle1" fontSize="small">
            .csv, .xls or .xlsx file size no more than 10MB
          </Typography>
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </Container>
  );
}

const Container = styled(FormControl)`
  display: flex;
  height: 16rem;
  width: 90%;
  margin: 0 auto;
  text-align: center;

  #input-file-upload {
    display: none;
  }

  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #ffffff;
  }

  #label-file-upload.drag-active {
    background-color: #f8fafc;
  }

  .icon {
    font-size: 7rem;
  }
  .upload-button {
    font-size: inherit;
    font-weight: inherit;
  }

  .upload-button:hover {
    text-decoration-line: underline;
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
`;
