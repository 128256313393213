import {useSearchParams} from "react-router-dom";
import PageContainer from "../components/PageContainer";
import {useEffect, useMemo, useState} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import {
  Add,
  CheckBoxOutlined,
  Close,
  ConfirmationNumberOutlined,
  ContactSupportOutlined,
  DeleteOutline,
  // DriveFileRenameOutline,
  ExpandMore,
} from "@mui/icons-material";
import {TabPanel} from "../components/CustomComponents";
import SearchBar from "../components/SearchBar";
import {useAppSelector} from "../redux/store";
import Sort from "../components/Sort";
import {Status, TicketPriority, TicketType} from "../redux/accountReducer";
import DeleteDialog from "../components/DeleteDialog";

export default function Help() {
  const [s] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(0);
  const {faqs, tickets} = useAppSelector((store) => store.accountReducer);
  const [newDialog, setNewDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    element: any;
    label: string;
  }>({open: false, label: " ", element: null});
  const [selected, setSelected] = useState<{[key: number]: boolean}>({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSelect(index: number) {
    const state = {...selected};
    if (state[index]) {
      delete state[index];
    } else {
      state[index] = true;
    }
    setSelected(state);
  }

  function handleDelete(element: TicketType | string[]) {
    console.log(element);
    setDeleteDialog({open: false, label: "", element: null});
  }
  useMemo(() => {
    const t = Number(s.get("t"));
    if (s.size && t < 3 && t !== currentTab) {
      console.log(t, s);
      setCurrentTab(t);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s]);
  return (
    <PageContainer title="Help & Support">
      <Box
        sx={{
          display: "flex",
          flexDirection: {md: "row", xs: "column"},
          padding: 4,
          gap: 2,
        }}
      >
        <Tabs
          value={currentTab}
          onChange={(e, v) => setCurrentTab(v)}
          sx={{
            borderRadius: "5px",
            width: "max-content",
            "& .MuiTabs-indicator": {
              display: "none",
            },
            "& .MuiTabs-flexContainer": {
              flexDirection: {md: "column", xs: "row"},
              gap: {xs: 2, md: 0},
            },
            "& .MuiTab-root": {
              "&.Mui-selected": {
                borderLeftColor: "primary.main",
              },
              //   height: "100%",
              backgroundColor: "background.paper",
              borderLeft: "4px solid",
              borderLeftColor: "background.paper",
              minHeight: "auto",
              alignItems: "flex-start",
              span: {
                display: "flex",
                gap: 1,
                alignItems: "center",
                width: "100%",
              },
            },
          }}
        >
          <Tab
            label={
              <span>
                <ContactSupportOutlined /> FAQ's
              </span>
            }
          />
          <Tab
            label={
              <span>
                <ConfirmationNumberOutlined />
                Support Ticket
              </span>
            }
          />
        </Tabs>
        <TabPanel index={0} value={currentTab} sx={{width: "100%", p: 0}}>
          <Box sx={{display: "flex", justifyContent: "space-between", mb: 2}}>
            <Typography variant="h5" fontWeight={600}>
              FAQ's
            </Typography>
            <SearchBar />
          </Box>
          <Stack
            sx={{
              gap: 2,
              ".MuiPaper-root": {
                boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.14)",
                "&::before": {display: "none"},
              },
            }}
          >
            {faqs.map((faq) => (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  {faq.title}
                </AccordionSummary>
                <AccordionDetails>{faq.description}</AccordionDetails>
                {/**/}
              </Accordion>
            ))}
            <Card>
              <CardContent sx={{textAlign: "center", p: 5}}>
                <Typography variant="h4" fontWeight={600}>
                  Still Need Help?
                </Typography>
                <Typography color="grey.600" my={1}>
                  Submit a ticket to our support dash board and one of our
                  customer assistants will get back to you as soon as possible.
                </Typography>
                <br />
                <Button size="large" variant="contained">
                  Submit Ticket
                </Button>
              </CardContent>
            </Card>
          </Stack>
        </TabPanel>
        <TabPanel index={1} value={currentTab} sx={{width: "100%", py: 0}}>
          <Box sx={{display: "flex", justifyContent: "space-between", mb: 2}}>
            <Typography variant="h5" fontWeight={600}>
              Support Tickets
            </Typography>
            {Object.keys(selected).length < 1 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                  width: "75%",
                }}
              >
                <SearchBar />
                <Sort />
                <Button variant="contained" onClick={() => setNewDialog(true)}>
                  <Add />
                  New Ticket
                </Button>
              </Box>
            ) : (
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteOutline />}
                onClick={() =>
                  setDeleteDialog({
                    label: "selected tickets",
                    open: true,
                    element: Object.keys(selected),
                  })
                }
              >
                Delete
              </Button>
            )}
          </Box>

          <TableContainer
            sx={{
              backgroundColor: "background.paper",
              borderRadius: "10px",
              th: {borderBottom: "none"},
              td: {
                borderBottom: "2px solid",
                borderColor: "background.default",
              },
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Date & Time</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tickets.map(
                  (ticket, index) =>
                    index < (page + 1) * rowsPerPage &&
                    index >= page * rowsPerPage && (
                      <TableRow key={ticket.id}>
                        <TableCell
                          padding="none"
                          onClick={() => handleSelect(index)}
                        >
                          <Checkbox
                            checked={!!selected[index]}
                            onChange={() => {}}
                            sx={{color: "grey.200"}}
                            checkedIcon={<CheckBoxOutlined />}
                          />
                        </TableCell>
                        <TableCell>{ticket.subject}</TableCell>
                        <TableCell>{TicketPriority[ticket.type]}</TableCell>
                        <TableCell>{ticket.time.toLocaleString()}</TableCell>
                        <TableCell align="center">
                          {ticket.status === Status.Closed ? (
                            <Chip
                              variant="outlined"
                              color="error"
                              label="Closed"
                              sx={(theme) => ({
                                backgroundColor: `rgb(from ${theme.palette.error.light} r g b / 0.2)`,
                                border: "none",
                                borderRadius: "2px",
                                p: "4px",
                                height: "auto",
                              })}
                            />
                          ) : ticket.status === Status.InProgress ? (
                            <Chip
                              variant="outlined"
                              color="warning"
                              label="Inprogress"
                              sx={(theme) => ({
                                backgroundColor: `rgb(from ${theme.palette.warning.light} r g b / 0.2)`,
                                border: "none",
                                borderRadius: "2px",
                                p: "4px",
                                height: "auto",
                              })}
                            />
                          ) : ticket.status === Status.Open ? (
                            <Chip
                              variant="outlined"
                              color="success"
                              label="Open"
                              sx={(theme) => ({
                                backgroundColor: `rgb(from ${theme.palette.success.light} r g b / 0.2)`,
                                border: "none",
                                borderRadius: "2px",
                                p: "4px",
                                height: "auto",
                              })}
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <ButtonGroup>
                            {/* <IconButton
                              color="primary"
                              onClick={() =>
                                setNewDialog(ticket)
                              }
                            >
                              <DriveFileRenameOutline />
                            </IconButton> */}
                            <IconButton
                              color="error"
                              onClick={() =>
                                setDeleteDialog({
                                  open: true,
                                  element: ticket,
                                  label: "ticket",
                                })
                              }
                            >
                              <DeleteOutline />
                            </IconButton>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={tickets.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <DeleteDialog
            open={deleteDialog.open}
            onClose={() => setDeleteDialog({...deleteDialog, open: false})}
            handleDelete={handleDelete}
            label={deleteDialog.label}
            element={deleteDialog.element}
          />
          <NewDialog open={newDialog} onClose={() => setNewDialog(false)} />
        </TabPanel>
      </Box>
    </PageContainer>
  );
}

function NewDialog({open, onClose}: {open: boolean; onClose: () => void}) {
  const [ticketData, setTicketData] = useState<TicketType>({} as TicketType);
  const [activeStep, setActiveStep] = useState(0);

  function handleTicketChange(obj: Partial<TicketType>) {
    setTicketData({...ticketData, ...obj});
  }
  function reset() {
    setTicketData({} as TicketType);
    setActiveStep(0);
    onClose();
  }

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      console.log(ticketData);
      reset();
    } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Dialog open={open} maxWidth="xl" onClose={onClose}>
      <DialogTitle position="relative" fontWeight={600} variant="h4">
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "30%",
            right: 7,
            border: "1px solid",
          }}
          size="small"
        >
          <Close />
        </IconButton>
        Create New Ticket
      </DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{mr: 1}}
          >
            Back
          </Button>
          <Box sx={{flex: "1 1 auto"}} />
          <Button onClick={handleNext}>
            {activeStep === steps.length - 1 ? "Submit" : "Next"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const steps = ["Select ticket category", "Create new ticket", "Submit ticket"];
