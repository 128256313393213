import {
  Typography,
} from "@mui/material";
import Sms from "../../components/Sms";
import { SmsType } from "../../redux/smsReducer";
import { useState } from "react";

export default function SendSms() {
  const [sms, setSms] = useState<SmsType>({
    to: '[]',
    from: '',
    message: '',
    id: -1,
  })

  return (
    <>
      <Typography
        variant="h3"
        fontSize={"1.7rem"}
        fontWeight={600}
        marginBottom={1}
      >
        Send New Message
      </Typography>
      <Typography variant="subtitle1">
        Enter the details you wish to include in your message. You can be
        descriptive as you want
      </Typography>
      <Sms sms={sms} handleSmsChange={(m)=>setSms({...sms, ...m})} />
    </>
  );
}
