import {Route, Routes} from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputBase,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import {useAppSelector} from "../../redux/store";
import TransactionHistory from "./TransactionHistory";

export default function Wallet() {
  return (
    <PageContainer title="Wallet">
      <Routes>
        <Route index element={<Home />} />
        <Route path="transaction-history" element={<TransactionHistory />} />
      </Routes>
    </PageContainer>
  );
}

function Home() {
  const user = useAppSelector((store) => store.accountReducer.user);
  return (
    <Stack sx={{mx: "auto", maxWidth: "sm", py: 4, gap: 4}}>
      <Card
        sx={{
          borderRadius: "15px",
          boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.07)",
        }}
      >
        <CardContent>
          <Typography variant="h6">Current Balance</Typography>
          <Box my={3}>
            <Typography>NGN</Typography>
            <Typography variant="h3" fontWeight={600}>
              {user.balance}
            </Typography>
          </Box>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            flexWrap="wrap"
          >
            <Typography fontSize="12px">Last Update: 24/01/24</Typography>
          </Stack>
        </CardContent>
      </Card>
      <Card
        sx={{
          borderRadius: "15px",
          boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.07)",
        }}
      >
        <CardContent component={Stack} sx={{gap: 3}}>
          <Typography variant="h4" fontWeight={600}>
            Wallet Topup
          </Typography>
          <FormControl fullWidth>
            <FormLabel sx={{fontWeight: "bold"}}>Enter Amount</FormLabel>
            <Box
              sx={{
                display: "flex",
                border: "1px solid",
                borderColor: "grey.400",
                borderRadius: "5px",
                ":has(.Mui-focused)": {borderColor: "primary.light"},
                select: {
                  backgroundColor: "background.default",
                  minWidth: "80px",
                },
                fieldset: {display: "none"},
              }}
            >
              <Select size="medium" native defaultValue={0}>
                <option value={0}>NGN (&#x20A6;)</option>
              </Select>

              <InputBase type="number" sx={{width: "100%", p: 1}} />
            </Box>
          </FormControl>
          <Box>
            <b>Select A Payment Channel</b>
            <List component={RadioGroup}
              sx={{
                width: "100%",
                bgcolor: "background.default",
                borderRadius: "5px",
              }}
            >
              <ListItem component={FormControlLabel} control={<Radio/>} label={<Stack>
                  <Typography color="grey.700" fontWeight='bold'>
                    Pay To Bank / Make A Transfer
                  </Typography>
                  <Typography color="grey.600">
                    Fee: &#x20A6;0.00 | Total: &#x20A6;5,000.00
                  </Typography>
                  <Typography fontSize="small" color="grey.400">
                    Instant Crediting | Available 247 | Bank Transfers via any
                    channel.
                  </Typography>
                </Stack>} />
            </List>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center", p: 1}}>
            <Button variant="contained" size="large" sx={{minWidth: "50%"}}>
              Proceed to Pay
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
}
