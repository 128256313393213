import {Tune} from "@mui/icons-material";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Typography,
} from "@mui/material";
import {useEffect, useState} from "react";

// type Props = {};
export default function Sort() {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  useEffect(() => {}, [from, to]);

  return (
    <div>
      <Button
        onClick={(e) => setAnchor(!anchor ? e.currentTarget : null)}
        variant="outlined"
        sx={{minWidth: "auto"}}
      >
        <Tune />
      </Button>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
        transformOrigin={{vertical: "top", horizontal: "right"}}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
        elevation={1}
      >
        <Typography sx={{px: 2, fontWeight: "bold"}}>Date</Typography>
        <MenuList
          sx={{
            display: "flex",
            "& *": {fontSize: "0.8rem"},
            "& >.MuiMenuItem-root:hover": {backgroundColor: "transparent"},
            "& .MuiInputBase-input": {p: 1},
          }}
        >
          <MenuItem>
            <Box>
              <Typography>From Date</Typography>
              <TextField
                variant="outlined"
                onChange={(e) => setFrom(e.target.value)}
                type="date"
              />
            </Box>
          </MenuItem>
          <MenuItem>
            <Box>
              <Typography>To Date</Typography>
              <TextField
                variant="outlined"
                onChange={(e) => setTo(e.target.value)}
                type="date"
              />
            </Box>
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
}
