import {Route, Routes} from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import { Box } from "@mui/material";
import ApiCredentials from "./ApiCredentials";
import ApiDocumentation from "./ApiDocumentation";
export default function Developers() {
  return (
      <PageContainer title="Developers">
          <Box sx={{ p: 2 }}>
            <Routes>
                <Route path="api-credentials" element={<ApiCredentials />} />
                <Route path="api-documentation" element={<ApiDocumentation />} />
            </Routes>
          </Box>
    </PageContainer>
  );
}
