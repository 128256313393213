import {styled, Theme, CSSObject} from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Logo from "./Logo";
import NavItems from "./NavItems";
import {Button, Card, CardActionArea, CardContent} from "@mui/material";
import {Upgrade} from "@mui/icons-material";
import {useState} from "react";

const drawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
  maxWidth: drawerWidth,
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [theme.breakpoints.down("md")]: {
    width: "fit-content",
  },
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled("div")(({theme}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({theme}) => ({
  variants: [
    {
      props: ({open}) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": {...openedMixin(theme), padding: "20px"},
      },
    },
    {
      props: ({open}) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": {...closedMixin(theme), padding: "2px"},
        "& .MuiCollapse-root, .MuiPaper-root.MuiCard-root": {display: "none"},
        "& .MuiList-root >.MuiBox-root": {margin: "auto", padding: "8px"},
        "& .MuiButtonBase-root.MuiListItemButton-root": {
          height: 40,
          width: 36,
          padding: "0 6px",
        },
      },
    },
  ],
}));

export default function Sidebar() {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{display: "flex", width: "57px"}}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Logo sx={{display: open ? "flex" : "none"}} />
          <IconButton onClick={toggleDrawer}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexGrow: "1",
          }}
        >
          <NavItems />
          <Card
            variant="outlined"
            sx={{
              textAlign: "center",
              borderRadius: "15px 0 15px 0",
              border: "none",
              backgroundImage: "linear-gradient(332deg, #1976d2, #5d97df)",
              color: "background.paper",
              p: 3,
            }}
          >
            <CardContent>
              <Typography variant="h6">ETC Pricing</Typography>
              <Typography sx={{fontSize: "small", fontFamily: "monospace"}}>
                Check our pricing plan on our website
              </Typography>
            </CardContent>
            <CardActionArea>
              <Button
                variant="contained"
                href="https://www.bulksms.eiffeltechng.com/Pricing"
                rel="noopener"
                target="_blank"
                startIcon={
                  <Upgrade
                    sx={{
                      color: "background.paper",
                      backgroundColor: "text.primary",
                      borderRadius: "20px",
                    }}
                  />
                }
                sx={{
                  boxShadow: "none",
                  backgroundColor: "background.paper",
                  color: "text.primary",
                  fontWeight: "bold",
                }}
              >
                Pricing Plan
              </Button>
            </CardActionArea>
          </Card>
        </Box>
      </Drawer>
    </Box>
  );
}
