import {createSlice} from "@reduxjs/toolkit";
const Dtemplates: SmsTemplateType[] = [
  {
    id: 1,
    title: "Sale Offer on this BLACK Friday",
    message: "Lorem Ipsium dolor sit amet, ",
  },
  {
    id: 2,
    title: "Opening restaurant in your area",
    message: "Lorem Ipsium dolor sit amet, ",
  },
];
const DCampaigns: CampaignType[] = [
  {
    id: 1,
    to: '["+2348138474740"]',
    from: "Senda",
    title: "offers message",
    created_at: new Date().toDateString(),
    message:
      "Loren Ipsum dolor sit amet, consectetur adipliscing elit, Loren Ipsum dolor sit amet, consectetur adipliscing elit",
  },
];
const DScheduleSms: ScheduledSmsType[] = [
  {
    id: 1,
    to: '["+2348138474740"]',
    from: "Senda",
    time: "02:22",
    date: "2024-12-01",
    message:
      "Loren Ipsum dolor sit amet, consectetur adipliscing elit, Loren Ipsum dolor sit amet, consectetur adipliscing elit",
  },
  {
    id: 2,
    to: '["+2348138474740"]',
    from: "Senda",
    time: "23:33",
    date: "2024-12-01",
    message:
      "Loren Ipsum dolor sit amet, consectetur adipliscing elit, Loren Ipsum dolor sit amet, consectetur adipliscing elit",
  },
];
const DRecurringSms: RecurringSmsType[] = [
  {
    id: 1,
    to: '["+2348138474740"]',
    from: "Senda",
    message:
      "Loren Ipsum dolor sit amet, consectetur adipliscing elit, Loren Ipsum dolor sit amet, consectetur adipliscing elit",
    cycle: 1,
    rate: 2,
    weekDays: [0, 2, 4],
    endType: 1,
    endValue: 3,
    startTime: "02:22",
    startDate: "2024-12-01",
  },
  {
    id: 2,
    to: '["+2348138474740"]',
    from: "Senda",
    message:
      "Loren Ipsum dolor sit amet, consectetur adipliscing elit, Loren Ipsum dolor sit amet, consectetur adipliscing elit",
    cycle: 2,
    rate: 2,
    weekDays: [0, 2, 4],
    endType: 1,
    endValue: 3,
    startTime: "02:22",
    startDate: "2024-12-01",
  },
];
const initialState = {
  templates: Dtemplates,
  schedulesSms: DScheduleSms,
  recurringSms: DRecurringSms,
  campaigns: DCampaigns,
};

const smsReducer = createSlice({
  name: "sms",
  initialState,
  reducers: {
    setSmsState: (state, action) => {
      ({
        templates: state.templates = [],
        schedulesSms: state.schedulesSms = [],
        recurringSms: state.recurringSms = [],
        campaigns: state.campaigns = [],
      } = action.payload);
    },
    addTemplate: (state, action) => {
      const index = state.templates.findIndex(
        (t) => t.id === action.payload.id
      );
      if (index >= 0) {
        state.templates[index] = {...state.templates[index], ...action.payload};
      } else {
        state.templates.push(action.payload);
      }
    },
    deleteTemplate: (state, action) => {
      state.templates = state.templates.filter(
        (t) => t.id !== action.payload.id
      );
    },
    addScheduleSms: (state, action) => {
      const index = state.schedulesSms.findIndex(
        (s) => s.id === action.payload.id
      );
      if (index >= 0) {
        state.schedulesSms[index] = {
          ...state.schedulesSms[index],
          ...action.payload,
        };
      } else {
        state.schedulesSms.push(action.payload);
      }
    },
    deleteScheduleSms: (state, action) => {
      state.schedulesSms = state.schedulesSms.filter(
        (s) => s.id !== action.payload.id
      );
    },
    addRecurringSms: (state, action) => {
      const index = state.recurringSms.findIndex(
        (r) => r.id === action.payload.id
      );
      if (index >= 0) {
        state.recurringSms[index] = {
          ...state.recurringSms[index],
          ...action.payload,
        };
      } else {
        state.recurringSms.push(action.payload);
      }
    },
    deleteRecurringSms: (state, action) => {
      state.recurringSms = state.recurringSms.filter(
        (r) => r.id !== action.payload.id
      );
    },
    addCampaign: (state, action) => {
      const index = state.campaigns.findIndex(
        (c) => c.id === action.payload.id
      );
      if (index >= 0) {
        state.campaigns[index] = {
          ...state.campaigns[index],
          ...action.payload,
        };
      } else {
        state.campaigns.push(action.payload);
      }
    },
    deleteCampaign: (state, action) => {
      state.campaigns = state.campaigns.filter(
        (c) => c.id !== action.payload.id
      );
    },
  },
});

export default smsReducer.reducer;
export const {
  setSmsState,
  addTemplate,
  deleteTemplate,
  addScheduleSms,
  deleteScheduleSms,
  addRecurringSms,
  deleteRecurringSms,
  addCampaign,
  deleteCampaign,
} = smsReducer.actions;

export enum RepeatCycle {
  Daily,
  Weekly,
  Monthly,
}
export enum RepeatCycleEnd {
  Never,
  "After Number of Occurrences",
  "Custom Day/Time",
}
export enum weekDays {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export type SmsTemplateType = {
  id: number;
  title: string;
  message: string;
};

export type SmsType = {
  id: number;
  to: string;
  from: string;
  message: string;
};

export type ScheduledSmsType = SmsType & {
  time: string;
  date: string;
};

export type RecurringSmsType = SmsType & {
  cycle: RepeatCycle;
  rate: number;
  weekDays: weekDays[];
  endType: RepeatCycleEnd;
  endValue: null | number | string;
  startTime: string;
  startDate: string;
};

export type CampaignType = SmsType & {
  title: string;
  created_at: string;
};
