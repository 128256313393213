import {Box, Stack, Typography} from "@mui/material";
import {Helmet} from "react-helmet";
import Profile from "./Profile";

type Props = {
  title?: string;
  description?: string;
  children?: JSX.Element | JSX.Element[];
  hideHeader?: boolean;
};

export default function PageContainer({
  title,
  description,
  hideHeader,
  children,
}: Props) {
  return (
    <Box component="section" width="100%">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {!hideHeader && (
        <Stack
          component="header"
          sx={{
            backgroundColor: "background.paper",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 3,
            width: "100%",
            p: 2,
          }}
        >
          <Typography fontSize="1.5rem" color="grey.600">
            {title}
          </Typography>
          <Profile sx={{width: "220px"}} />
        </Stack>
      )}
      {children}
    </Box>
  );
}
