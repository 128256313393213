import {createSlice} from "@reduxjs/toolkit";

const Departments = [
  "contacts",
  "sms",
  "campaings",
  "wallet",
  "api",
  "personal",
];

const settings: SettingsType = {
  payment: true,
  pricing: false,
  recommendation: false,
  transaction: true,
};

const transactions: TransactionType[] = new Array(15)
  .fill("")
  .map((arr, index) => ({
    id: 100000000 + index,
    date: new Date().toDateString(),
    amount: index * 100,
    status: Boolean(index % 3),
  }));

const user: UserType = {
  "First Name": "Robert",
  "Last Name": "Fox",
  Email: "robert.fox@mail.com",
  "Phone Number": "+(393) 241-6353",
  image: null,
  balance: 1000,
};

const faqs: FaqType[] = [
  {
    title: "What is Lorem Ipsum?",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  },
  {
    title: "Where does it come from?",
    description:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  },
  {
    title: "Why do we use it?",
    description:
      "t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
  },
  {
    title: "Where can I get some?",
    description:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
  },
  {
    title: "What is The standard Lorem Ipsum passage? ",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
];
const tickets: TicketType[] = Array(15).fill('').map((a,i) => ({
  id: i,
  subject: 'Payment Issue',
  type: i % 3,
  description: '',
  time: new Date(),
  status: i % 3,
  department: Departments[i % 5],
}));
const initialState = {
  user,
  settings,
  transactions,
  faqs,
  tickets,
};
const accountReducer = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccountState(state, action) {
      ({transactions: state.transactions = [], user: state.user} =
        action.payload);
    },
    addTransaction(state, action) {
      const index = state.transactions.findIndex(
        (t) => t.id === action.payload.id
      );
      if (index >= 0) {
        state.transactions[index] = {
          ...state.transactions[index],
          ...action.payload,
        };
      } else {
        state.transactions.push(action.payload);
      }
    },
    deletTransaction(state, action) {
      state.transactions = state.transactions.filter(
        (t) => t.id !== action.payload.id
      );
    },
  },
});

export default accountReducer.reducer;
export const {setAccountState, addTransaction, deletTransaction} =
  accountReducer.actions;

export enum TicketPriority {
  Critical,
  High,
  Medium,
  Low,
}

export enum Status {
  Open,
  InProgress,
  Closed,
}


export type ApiCredentialType = {
  username: string;
  key: string;
  email: string;
  phone: string;
};

export type TicketType = {
  id: number,
  subject: string;
  type: TicketPriority;
  description: string;
  file?: string;
  time: Date;
  status: Status;
  department: (typeof Departments)[number];
};

export type FaqType = {
  title: string;
  description: string;
};

export type UserType = {
  "First Name": string;
  "Last Name": string;
  Email: string;
  "Phone Number": string;
  image: string | null;
  balance: number;
};

export type SettingsType = {
  payment: boolean;
  pricing: boolean;
  recommendation: boolean;
  transaction: boolean;
};

export type TransactionType = {
  id: number;
  date: string;
  amount: number;
  status: boolean;
};
