import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Select,
  SelectChangeEvent,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextareaAutosize,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import PageContainer from "../components/PageContainer";
import SearchBar from "../components/SearchBar";
import {useEffect, useState} from "react";
import {
  Add,
  CheckBoxOutlined,
  Close,
  DeleteOutline,
  DriveFileRenameOutline,
  Tune,
} from "@mui/icons-material";
import DragDropFile from "../components/DragDropFile";
import {File, TabPanel} from "../components/CustomComponents";
import DeleteDialog from "../components/DeleteDialog";
import {useAppSelector} from "../redux/store";
import {ContactGroupType, ContactType} from "../redux/contactReducer";
import Sort from "../components/Sort";

type TDialog = {
  open: boolean;
  contact?: ContactType;
  group?: ContactGroupType;
};
export default function Contacts() {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editDialog, setEditDialog] = useState<TDialog>({open: false});
  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    element: any;
    label: string;
  }>({open: false, label: " ", element: null});
  const [currentTab, setCurrentTab] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {contacts, groups} = useAppSelector((store) => store.contactReducer);
  const [selected, setSelected] = useState<{[key: number]: boolean}>({});

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSelect(contactIndex: number) {
    const state = {...selected};
    if (state[contactIndex]) {
      delete state[contactIndex];
    } else {
      state[contactIndex] = true;
    }
    setSelected(state);
  }

  function handleDelete(element: ContactType | string[] | ContactGroupType) {
    console.log(element);
    setDeleteDialog({open: false, label: "", element: null});
  }

  useEffect(() => {
    // reset to tab defaults
    setSelected({});
    setPage(0);
  }, [currentTab]);

  return (
    <PageContainer title="Contacts">
      <Toolbar sx={{justifyContent: "space-between"}}>
        <Typography fontWeight={600} fontSize={"2rem"}>
          Contacts
        </Typography>
        <TabPanel index={0} value={currentTab}>
          {Object.keys(selected).length < 1 ? (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "flex-end",
              }}
            >
              <SearchBar width="60%" />
              <Sort />
              <Button
                startIcon={<Add />}
                variant="contained"
                onClick={() => setOpenDialog(true)}
              >
                New Contact
              </Button>
            </Box>
          ) : (
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteOutline />}
              size="large"
              onClick={() =>
                setDeleteDialog({
                  label: "contacts",
                  open: true,
                  element: Object.keys(selected),
                })
              }
            >
              Delete
            </Button>
          )}
        </TabPanel>
        <TabPanel index={1} value={currentTab}>
          {Object.keys(selected).length < 1 ? (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "flex-end",
              }}
            >
              <SearchBar width="60%" />
              <Button
                onClick={(e) => setAnchor(!anchor ? e.currentTarget : null)}
                variant="outlined"
                sx={{minWidth: "auto"}}
              >
                <Tune />
              </Button>
              <Button
                startIcon={<Add />}
                variant="contained"
                onClick={() => setEditDialog({open: true})}
              >
                New Group
              </Button>
            </Box>
          ) : (
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteOutline />}
              size="large"
              onClick={() =>
                setDeleteDialog({
                  label: "groups",
                  open: true,
                  element: Object.keys(selected),
                })
              }
            >
              Delete
            </Button>
          )}
          <Menu
            anchorEl={anchor}
            open={Boolean(anchor)}
            onClose={() => setAnchor(null)}
            transformOrigin={{vertical: "top", horizontal: "right"}}
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
            elevation={1}
          >
            <Typography sx={{px: 2, fontWeight: "bold"}}>
              Alphabetically
            </Typography>
            <MenuList>
              <MenuItem>
                <FormControlLabel control={<Checkbox />} label="A -Z" />
              </MenuItem>
            </MenuList>
          </Menu>
        </TabPanel>
      </Toolbar>
      <Toolbar>
        <Tabs
          value={currentTab}
          onChange={(e, v) => setCurrentTab(v)}
          sx={{
            borderBottom: "1px solid",
            borderColor: "grey.400",
            width: "100%",
            ".MuiTab-root": {
              fontSize: "1.2rem",
            },
            ".MuiTab-root.Mui-selected": {
              fontWeight: 600,
            },
          }}
        >
          <Tab label="All Contacts" />
          <Tab label="Contact Groups" />
        </Tabs>
      </Toolbar>
      <Box sx={{px: 3}}>
        <TabPanel value={currentTab} index={0}>
          <Typography variant="h4" fontWeight={600}>
            All Contacts
          </Typography>
          <Typography variant="subtitle1">
            Use contacts to target specific customers, sales leads and more.
          </Typography>
          <Paper elevation={0} sx={{my: 3}}>
            <Table
              sx={{
                th: {color: "grey.500", py: 3, borderBottom: "none"},
                tr: {textTransform: "capitalize"},
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {Object.keys(contacts[0]).map(
                    (key) =>
                      key !== "id" && <TableCell key={key}>{key}</TableCell>
                  )}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contacts.map(
                  (contact, contactIndex) =>
                    contactIndex < (page + 1) * rowsPerPage &&
                    contactIndex >= page * rowsPerPage && (
                      <TableRow key={contactIndex}>
                        <TableCell
                          padding="none"
                          onClick={() => handleSelect(contactIndex)}
                        >
                          <Checkbox
                            checked={!!selected[contactIndex]}
                            onChange={() => {}}
                            sx={{color: "grey.200"}}
                            checkedIcon={<CheckBoxOutlined />}
                          />
                        </TableCell>
                        {Object.entries(contact).map(
                          ([key, value]) =>
                            key !== "id" && (
                              <TableCell key={key}>
                                {key === "Group"
                                  ? groups.find((g) => g.id === value)?.Name
                                  : value}
                              </TableCell>
                            )
                        )}
                        <TableCell>
                          <ButtonGroup>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                setEditDialog({open: true, contact})
                              }
                            >
                              <DriveFileRenameOutline />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() =>
                                setDeleteDialog({
                                  open: true,
                                  element: contact,
                                  label: `contact`,
                                })
                              }
                            >
                              <DeleteOutline />
                            </IconButton>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </Paper>
          <TablePagination
            component="div"
            count={contacts.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Typography variant="h4" fontWeight={600}>
            Groups
          </Typography>
          <Typography variant="subtitle1">
            Contact groups are segments of your opted-in-contacts. Use groups to
            target specific customers, sales leads and more.
          </Typography>
          <Paper elevation={0} sx={{my: 3}}>
            <Table sx={{th: {color: "grey.500", py: 3, borderBottom: "none"}}}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {Object.keys(groups[0]).map(
                    (key) =>
                      key !== "id" && <TableCell key={key}>{key}</TableCell>
                  )}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups.map(
                  (group, groupIndex) =>
                    groupIndex < (page + 1) * rowsPerPage &&
                    groupIndex >= page * rowsPerPage && (
                      <TableRow key={groupIndex}>
                        <TableCell
                          padding="none"
                          onClick={() => handleSelect(groupIndex)}
                        >
                          <Checkbox
                            checked={!!selected[groupIndex]}
                            onChange={() => {}}
                            sx={{color: "grey.200"}}
                            checkedIcon={<CheckBoxOutlined />}
                          />
                        </TableCell>
                        {Object.entries(group).map(
                          ([key, value]) =>
                            key !== "id" && (
                              <TableCell key={key}>{value}</TableCell>
                            )
                        )}
                        <TableCell>
                          <ButtonGroup>
                            <IconButton
                              color="primary"
                              onClick={() => setEditDialog({open: true, group})}
                            >
                              <DriveFileRenameOutline />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() =>
                                setDeleteDialog({
                                  open: true,
                                  element: group,
                                  label: "group",
                                })
                              }
                            >
                              <DeleteOutline />
                            </IconButton>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </Paper>
        </TabPanel>
        {currentTab < 1 ? (
          <NewContactDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            groups={groups}
          />
        ) : (
          <NewGroupDialog
            open={editDialog.open}
            onClose={() => setEditDialog({open: false})}
            contacts={contacts}
            group={editDialog.group}
          />
        )}
        {editDialog.contact && (
          <EditDialog
            open={editDialog.open}
            contact={editDialog.contact}
            onClose={() => setEditDialog({...editDialog, open: false})}
            groups={groups}
          />
        )}
        <DeleteDialog
          open={deleteDialog.open}
          onClose={() => setDeleteDialog({...deleteDialog, open: false})}
          handleDelete={handleDelete}
          label={deleteDialog.label}
          element={deleteDialog.element}
        />
      </Box>
    </PageContainer>
  );
}

function NewContactDialog({
  open,
  onClose,
  groups,
}: {
  open: boolean;
  onClose: () => void;
  groups: ContactGroupType[];
}) {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <TabPanel value={currentTab} index={1}>
        <DragDropFile />
      </TabPanel>
      <DialogTitle sx={{position: "relative"}}>
        <TabPanel value={currentTab} index={0}>
          <Typography variant="h4" fontWeight={600}>
            New Contact
          </Typography>
          <Typography variant="subtitle1">
            Add Individual contact in your contact list
          </Typography>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Typography variant="h4" fontWeight={600}>
            Upload File
          </Typography>
          <Typography variant="subtitle1">
            Upload your contact from a .csv, .xls or .xlsx file.
          </Typography>
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <Typography variant="h4" fontWeight={600}>
            Copy & Paste From File
          </Typography>
          <Typography variant="subtitle1">
            Enter your contact number one by one seperating them with a comma (
            , )
          </Typography>
        </TabPanel>
        <IconButton
          onClick={onClose}
          color="primary"
          sx={{
            position: "absolute",
            top: "30%",
            right: 7,
            border: "1px solid",
          }}
          size="small"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Tabs
          value={currentTab}
          onChange={(e, v) => setCurrentTab(v)}
          sx={{
            backgroundColor: "background.default",
            borderRadius: "5px",
            p: "5px",
            border: "1px solid",
            borderColor: "primary.light",
            width: "fit-content",
            "& .MuiTabs-indicator": {
              height: "100%",
              opacity: 0.1,
              borderRadius: "5px",
            },
            "& .MuiTab-root": {
              height: "100%",
              minHeight: "auto",
            },
          }}
        >
          <Tab label="Individually" />
          <Tab label="Upload" />
          <Tab label="Copy & Paste" />
        </Tabs>
        <TabPanel
          value={currentTab}
          index={0}
          sx={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gap: 3,
            ".MuiFormLabel-root": {
              fontWeight: "600",
            },
            ".MuiFormLabel-asterisk": {color: "red"},
            ".MuiInputBase-root": {
              m: 0,
              border: "1px solid",
              borderColor: "grey.300",
              borderRadius: "5px",
              padding: "5px",
              fontSize: "small",
              width: "100%",
              "&::before, &::after, .MuiOutlinedInput-notchedOutline": {
                display: "none",
              },
            },
          }}
        >
          <FormControl required>
            <FormLabel>First Name</FormLabel>
            <Input placeholder="Enter first name" />
          </FormControl>
          <FormControl required>
            <FormLabel>Last Name</FormLabel>
            <Input placeholder="Enter last name" />
          </FormControl>
          <FormControl required>
            <FormLabel>Phone Number</FormLabel>
            <Input type="tel" />
          </FormControl>
          <FormControl required>
            <FormLabel>Groups</FormLabel>
            <Select
              native
              sx={{".MuiInputBase-input": {p: "4px 5px"}}}
              placeholder="select"
            >
              {groups.map((group, index) => (
                <option key={index} value={index}>
                  {group.Name}
                </option>
              ))}
            </Select>
          </FormControl>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <File
            file={
              {name: "files-1.csv", size: 6560006, type: "text/csv"} as File
            }
          />
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <Typography variant="subtitle1">
            Please note that only the phone number field is required
          </Typography>
          <TextField
            multiline
            placeholder="e.g +393123456789, Samul,
              +3931234567890"
            fullWidth
            variant="outlined"
            minRows={7}
            sx={{
              mt: 1,
              backgroundColor: "background.default",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "grey.300",
              },
            }}
          />
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
function EditDialog({
  open,
  onClose,
  contact,
  groups,
}: {
  open: boolean;
  onClose: () => void;
  contact: ContactType;
  groups: ContactGroupType[];
}) {
  const [contactData, setContactData] = useState({...contact});

  function handleInputChange(value: string, key: keyof ContactType) {
    setContactData((s) => {
      if (key === "Group") {
        s[key] = parseInt(value);
      } else if (key !== "id") {
        s[key] = value;
      }
      return s;
    });
  }
  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle position="relative">
        <Typography variant="h4" fontWeight={600}>
          Edit Contact
        </Typography>
        <Typography variant="subtitle1">
          Edit Individual contact in your contact list
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "30%",
            right: 7,
            border: "1px solid",
          }}
          size="small"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gap: 3,
            ".MuiFormLabel-root": {
              fontWeight: "600",
            },
            ".MuiFormLabel-asterisk": {color: "red"},
            ".MuiInputBase-root": {
              m: 0,
              border: "1px solid",
              borderColor: "text.disabled",
              borderRadius: "5px",
              padding: "5px",
              fontSize: "small",
              width: "100%",
              "&::before, &::after, .MuiOutlinedInput-notchedOutline": {
                display: "none",
              },
            },
          }}
        >
          {(Object.keys(contact) as Array<keyof ContactType>).map(
            (ck) =>
              ck !== "Date" &&
              ck !== "id" && (
                <FormControl key={ck} required>
                  <FormLabel>{ck}</FormLabel>
                  {ck === "Group" ? (
                    <Select
                      native
                      sx={{".MuiInputBase-input": {p: "4px 5px"}}}
                      placeholder="select"
                    >
                      {groups.map((group, index) => (
                        <option key={index} value={index}>
                          {group.Name}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      value={contactData[ck]}
                      onChange={(e) => handleInputChange(e.target.value, ck)}
                      placeholder={"Enter " + ck}
                    />
                  )}
                </FormControl>
              )
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function NewGroupDialog({
  open,
  onClose,
  contacts,
  group,
}: {
  open: boolean;
  onClose: () => void;
  contacts: ContactType[];
  group?: ContactGroupType;
}) {
  const [selContacts, setSelContacts] = useState<number[]>([]);
  const [groupName, setGroupName] = useState("");
  const [groupDesc, setGroupDesc] = useState("");

  const handleChange = (event: SelectChangeEvent<typeof selContacts>) => {
    const {
      target: {value},
    } = event;

    setSelContacts(
      // On autofill we get a stringified value.
      (typeof value === "string"
        ? value.split(",").map((e) => String(e))
        : value) as number[]
    );
  };
  useEffect(() => {
    if (group) {
      setGroupName(group.Name);
      setGroupDesc(group.Description);
      setSelContacts(
        contacts
          .filter((contact) => contact.Group === group.id)
          .map((c) => c.id)
      );
    } else {
      setGroupName("");
      setGroupDesc("");
      setSelContacts([]);
    }
  }, [contacts, group]);
  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle sx={{position: "relative"}} fontWeight={600}>
        Create Group
        <IconButton
          onClick={onClose}
          color="primary"
          sx={{
            position: "absolute",
            top: "30%",
            right: 7,
            border: "1px solid",
          }}
          size="small"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "grid",
          gridTemplateColumns: "auto",
          gap: 3,
          ".MuiFormLabel-root": {
            fontWeight: "600",
          },
          ".MuiFormLabel-asterisk": {color: "red"},
          ".Mui-focused": {color: "grey.600"},
          ".MuiInputBase-root, textarea": {
            m: 0,
            border: "1px solid",
            borderColor: "grey.300",
            borderRadius: "5px",
            padding: "5px",
            fontSize: "small",
            width: "100%",
            outline: "none",
            "&::before, &::after, .MuiOutlinedInput-notchedOutline": {
              display: "none",
            },
          },
        }}
      >
        <FormControl required>
          <FormLabel>Group Name</FormLabel>
          <Input
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder="Enter name"
          />
        </FormControl>
        <FormControl required>
          <FormLabel sx={{"&:has(+textarea:focus)": {color: "primary.main"}}}>
            Description
          </FormLabel>
          <TextareaAutosize
            value={groupDesc}
            onChange={(e) => setGroupDesc(e.target.value)}
            minRows={5}
            placeholder="Describe briefly..."
          />
        </FormControl>
        <FormControl required>
          <FormLabel>Select Contacts</FormLabel>
          <Select
            multiple
            value={selContacts}
            onChange={handleChange}
            renderValue={(selected) =>
              selected
                .map(
                  (index) =>
                    `${contacts[index]["First Name"]} ${contacts[index]["Last Name"]}`
                )
                .join(", ")
            }
            placeholder="select"
          >
            {contacts.map((contact) => (
              <MenuItem key={contact.id} value={contact.id}>
                <Checkbox checked={selContacts.includes(contact.id)} />
                <ListItemText
                  primary={contact["First Name"] + " " + contact["Last Name"]}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
