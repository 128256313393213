import {
  ConfirmationNumberOutlined,
  ExpandMore,
  Logout,
  NotificationsNone,
  PersonOutline,
  SettingsOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import {useState} from "react";
import {useAppSelector} from "../redux/store";
import {Link} from "react-router-dom";

export default function Profile({sx = {}}: {sx?: SxProps}) {
  const user = useAppSelector((store) => store.accountReducer.user);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(open ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        ...sx,
      }}
    >
      <IconButton
        size="large"
        sx={(theme) => ({
          backgroundColor: `rgb(from ${theme.palette.primary.light} r g b / 0.1)`,
          color: "inherit",
          p: "3px",
          borderRadius: "7px",
        })}
      >
        <Badge color="error" variant="dot" badgeContent="" overlap="circular">
          <NotificationsNone fontSize="large" />
        </Badge>
      </IconButton>
      <Button
        startIcon={<Avatar />}
        sx={{color: "inherit", p:0}}
        endIcon={<ExpandMore />}
        onClick={handleClick}
        disableRipple
      >
        <Stack>
          <Typography sx={{fontSize: "14px", fontWeight: "600"}}>
            {user["First Name"]} {user["Last Name"]}
          </Typography>
          <Typography
            sx={{fontSize: "12px", lineHeight: "1", color: "grey.500"}}
            variant="subtitle1"
          >
            User
          </Typography>
        </Stack>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
                a: {display: "flex", alignItems: "center", gap: 1, color: 'grey.700', fontSize: '0.8rem'},
              },
            },
          }}
          transformOrigin={{horizontal: "right", vertical: "top"}}
          anchorOrigin={{horizontal: "right", vertical: "bottom"}}
        >
          <MenuItem>
            <Link to="/settings?t=0">
              <PersonOutline /> Profile Info
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/settings?t=1">
              <SettingsOutlined /> Change Password
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/help-support?t=1">
              <ConfirmationNumberOutlined /> Support Tickets
            </Link>
          </MenuItem>
          <Divider />
          <MenuItem>
            <Link to="/logout">
              <Logout /> Logout
            </Link>
          </MenuItem>
        </Menu>
      </Button>
    </Box>
  );
}
