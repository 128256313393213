import { configureStore } from "@reduxjs/toolkit";
import contactReducer from "./contactReducer";
import accountReducer from "./accountReducer";
import smsReducer from "./smsReducer";
import { useDispatch, useSelector } from "react-redux";

export const store = configureStore({
  reducer: {
    contactReducer,
    smsReducer,
    accountReducer,
  }
});
export const useAppDispatch = useDispatch.withTypes<typeof store.dispatch>();
export const useAppSelector = useSelector.withTypes<ReturnType<typeof store.getState>>();
export default store;

