import Sidebar from "./components/Sidebar";
import "./css/App.css";
import {ThemeProvider} from "@emotion/react";
import {Box, createTheme} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Contacts from "./pages/Contacts";
import {grey} from "@mui/material/colors";
import BulkSms from "./pages/BulkSms";
import Campaigns from "./pages/Campaigns";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Wallet from "./pages/Wallet";
import Developers from "./pages/Developers";
import Settings from "./pages/Settings";
import Help from "./pages/Help";

function App() {
  const theme = createTheme({
    palette: {background: {default: "#f0f6ff"}},
    typography: {
      h1: {fontSize: "3rem"},
      h2: {fontSize: "2.5rem"},
      h3: {fontSize: "2rem"},
      h4: {fontSize: "1.5rem"},
      h5: {fontSize: "1.2rem"},
      button: {textTransform: "none", textAlign: "left"},
      subtitle1: {lineHeight: 1, fontSize: "0.9rem", color: grey[500]},
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "30vw",
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            component={"main"}
            sx={{
              display: "flex",
              backgroundColor: "background.default",
              minHeight: "100vh",
            }}
          >
            <Sidebar />
            <Routes>
              <Route path="/" element={<Dashboard />}></Route>
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="/contacts" element={<Contacts />}></Route>
              <Route path="/bulk-sms/*" element={<BulkSms />} />
              <Route path="/campaigns" element={<Campaigns />} />
              <Route path="/wallet/*" element={<Wallet />} />
              <Route path="/developers/*" element={<Developers />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/help-support" element={<Help />} />
              <Route path="/logout" element={<Dashboard />} />
              <Route path="/*" element={<div>Not Found</div>}>
                <Route path="/*" element={<div>Not Found</div>}></Route>
              </Route>
            </Routes>
          </Box>
        </LocalizationProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
