import {Route, Routes} from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import SendSms from "./SendSms";
import {Box, Paper} from "@mui/material";
import ScheduleSms from "./ScheduleSms";
import RecurringSms from "./RecurringSms";

export default function BulkSms() {
  return (
    <PageContainer title="Bulk SMS">
      <Box sx={{p: 4}}>
        <Paper sx={{p: 4, borderRadius: '10px'}} elevation={0}>
          <Routes>
            <Route path="send-sms" element={<SendSms />} />
            <Route path="scheduled-sms" element={<ScheduleSms />} />
            <Route path="recurring-sms" element={<RecurringSms />} />
          </Routes>
        </Paper>
      </Box>
    </PageContainer>
  );
}
