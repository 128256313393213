import {Close, WarningAmber} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";

export default function DeleteDialog({
  open,
  onClose,
  handleDelete,
  label,
  element,
}: {
  open: boolean;
  onClose: () => void;
  handleDelete: (e: any) => void;
  label: string;
  element: any;
}) {
  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle
        position="relative"
        textAlign="center"
        fontWeight={600}
        fontSize="1.6rem"
        sx={{p: 4}}
      >
        <Box>
          <WarningAmber
            sx={{
              p: 2,
              my: 1,
              borderRadius: "50%",
              backgroundColor: "orange",
              fontSize: "5rem",
              color: "white",
            }}
          />
        </Box>
        Are you sure you want to delete {label}?
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 7,
            right: 7,
            border: "1px solid",
          }}
          size="small"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogActions sx={{justifyContent: "center", pb: 4, gap: 2}}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => handleDelete(element)}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
