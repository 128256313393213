import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {
  AccountBalanceWallet,
  Campaign,
  Code,
  ContactPage,
  Dashboard,
  ExpandMore,
  Info,
  Settings,
  Sms,
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

type NavItemProp = {
  title: string;
  icon: ReactJSXElement;
  asSublink?: Boolean;
  sublinks?: string[];
};
const Navigation: NavItemProp[] = [
  {
    title: "Dashboard",
    icon: <Dashboard />,
  },
  {
    title: "Contacts",
    icon: <ContactPage />,
  },
  {
    title: "Bulk SMS",
    icon: <Sms />,
    sublinks: ["Send SMS", "Templates", "Scheduled SMS", "Recurring SMS"],
  },
  {
    title: "Campaigns",
    icon: <Campaign />,
  },
  {
    title: "Wallet",
    icon: <AccountBalanceWallet />,
    asSublink: true,
    sublinks: ["Transaction History"],
  },
  {
    title: "Developers",
    icon: <Code />,
    sublinks: ["API Credentials", "API Documentation"],
  },
  {
    title: "Settings",
    icon: <Settings />,
  },
  {
    title: "Help & Support",
    icon: <Info />,
  },
];

export default function NavItems() {
  const [selectedIndex, setSelectedIndex] = useState<null | number>(null);
  const location = useLocation();
  const navigate = useNavigate();

  function handleListItemClick(index: number, item: NavItemProp) {
    let link = titleToLink(item.title);
    if (item.sublinks && !item.asSublink) {
      link += titleToLink(item.sublinks[0]);
    }
    navigate(link);
    setSelectedIndex(index === selectedIndex ? null : index);
  }
  useEffect(() => {
    setSelectedIndex(Navigation.findIndex((item) => checkSelected(item.title)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function titleToLink(title: string) {
    return `/${title.trim().replace(/\W+/g, "-").toLowerCase()}`;
  }
  function checkSelected(title: string, subLink?: string) {
    const pathnames = location.pathname.split("/");
    const subLinkMatch = subLink
      ? pathnames[2] === titleToLink(subLink).split("/")[1]
      : true;

    return subLinkMatch && pathnames[1] === titleToLink(title).split("/")[1];
  }
  return (
    <List component={"nav"} sx={{color: "grey.700"}}>
      {Navigation.map((item, index) => (
        <Box key={"NavItem" + index}>
          <ListItemButton
            selected={checkSelected(item.title)}
            onClick={() => handleListItemClick(index, item)}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "primary.main",
                color: "primary.contrastText",
                my: "5px",
              },
              "&.Mui-selected:hover": {
                backgroundColor: "primary.dark",
              },
              "&:hover": {
                backgroundColor: "primary.light",
                color: "primary.contrastText",
              },
              borderRadius: "10px",
            }}
          >
            <ListItemIcon sx={{color: "inherit"}}>{item.icon}</ListItemIcon>
            <ListItemText>{item.title}</ListItemText>
            {item.sublinks && (
              <ExpandMore
                sx={{
                  transition: "0.5s",
                  transform: `rotate(${selectedIndex === index ? 180 : 0}deg)`,
                }}
              />
            )}
          </ListItemButton>
          {item.sublinks && (
            <Collapse in={selectedIndex === index}>
              <List dense>
                {item.sublinks.map((subLink) => (
                  <ListItemButton
                    key={"subItem" + subLink}
                    selected={checkSelected(item.title, subLink)}
                    onClick={() =>
                      navigate(titleToLink(item.title) + titleToLink(subLink))
                    }
                    sx={(theme) => ({
                      "&.Mui-selected": {
                        color: "primary.contrastText",
                        boxShadow:
                          "-3px 0px 0px 0px " + theme.palette.primary.main,
                        backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.light}, transparent)`,
                        my: "5px",
                      },
                      "&.Mui-selected:hover": {
                        backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main}, transparent)`,
                      },
                      "&:hover": {
                        backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.light}, transparent)`,
                        color: "primary.contrastText",
                      },
                      borderRadius: "8px",
                      ml: "15px",
                    })}
                  >
                    <ListItemText>{subLink}</ListItemText>
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          )}
        </Box>
      ))}
    </List>
  );
}
