import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import PageContainer from "../components/PageContainer";
import Profile from "../components/Profile";
import SearchBar from "../components/SearchBar";
import {
  CalendarToday,
  CloudDownload,
  MarkEmailRead,
  PeopleAlt,
  PlaylistAdd,
  Telegram,
} from "@mui/icons-material";
import {useAppSelector} from "../redux/store";

export default function Dashboard() {
  const {
    accountReducer: {transactions, user},
    contactReducer: { contacts, groups },
    smsReducer: {schedulesSms, campaigns}
  } = useAppSelector((store) => store);
  function getGreeting() {
    const hour = new Date().getHours();
    return hour < 12
      ? "Morning"
      : hour < 16
        ? "Afternoon"
        : hour < 20
          ? "Evening"
          : "Night";
  }
  return (
    <PageContainer title="Home" description="dashboard" hideHeader>
      <Stack sx={{flexDirection: {lg: "row"}, height: "100%"}}>
        <Stack sx={{width: {lg: "70%"}, p: 4, gap: 5}}>
          <Profile sx={{display: {lg: "none", xs: "flex"}, mb: -4}} />
          <Stack
            sx={{
              flexDirection: {xs: "row"},
              justifyContent: "space-between",
              flexWrap: "wrap-reverse",
              rowGap: "25px",
            }}
          >
            <Stack>
              <Typography color="grey.600">
                Hello {user["First Name"]}!
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                Good {getGreeting()}!
              </Typography>
            </Stack>
            <SearchBar backgroundColor="#fffc" />
          </Stack>

          <Stack gap={3}>
            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
              <Button
                variant="contained"
                size="large"
                startIcon={<CloudDownload />}
              >
                Download Stats
              </Button>
            </Box>
            <Stack direction="row" flexWrap="wrap" gap="20px">
              <StatLabel color={"limegreen"}>
                <Chip avatar={<Telegram />} label="SMS Sent" />
                <Typography>34</Typography>
              </StatLabel>
              <StatLabel color={"hotpink"}>
                <Chip avatar={<MarkEmailRead />} label="Campaign Sent" />
                <Typography>{campaigns.length}</Typography>
              </StatLabel>
              <StatLabel color={"slateblue"}>
                <Chip avatar={<CalendarToday />} label="Sms Scheduled" />
                <Typography>{schedulesSms.length}</Typography>
              </StatLabel>
              <StatLabel color={"coral"}>
                <Chip avatar={<PeopleAlt />} label="Contacts" />
                <Typography>{contacts.length}</Typography>
              </StatLabel>
              <StatLabel color={"indianred"}>
                <Chip avatar={<PlaylistAdd />} label="Groups" />
                <Typography>{groups.length}</Typography>
              </StatLabel>
            </Stack>
          </Stack>
        </Stack>

        <Paper
          elevation={0}
          sx={{
            width: {lg: "30%", xs: "90%"},
            minWidth: {sm: "350px"},
            p: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            margin: "0 auto 24px",
          }}
        >
          <Profile sx={{display: {lg: "flex", xs: "none"}}} />
          <Card
            sx={{
              backgroundColor: "primary.main",
              color: "primary.contrastText",
              borderRadius: "15px",
            }}
          >
            <CardContent>
              <Typography variant="h6">Current Balance</Typography>
              <Box my={3}>
                <Typography>NGN</Typography>
                <Typography variant="h3" fontWeight={600}>
                  {user.balance}
                </Typography>
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
              >
                <Button
                  variant="contained"
                  href="/wallet"
                  sx={{
                    boxShadow: "none",
                    backgroundColor: "background.paper",
                    color: "text.primary",
                    fontWeight: "bold",
                    px: 4,
                  }}
                >
                  Top up
                </Button>
                <Typography fontSize="12px">Last Update: 24/01/24</Typography>
              </Stack>
            </CardContent>
          </Card>
          <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5">Recent Transactions</Typography>
              <Button
                href="/wallet/transaction-history"
                sx={{textDecoration: "underline !important"}}
              >
                See All
              </Button>
            </Stack>
            <TableContainer
              sx={{
                backgroundColor: "background.default",
                borderRadius: "10px",
                p: 2,
              }}
            >
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.slice(0, 4).map((trx) => (
                    <TableRow
                      key={trx.id}
                      sx={(theme) => ({
                        backgroundColor: "background.paper",
                        borderRadius: "10px",
                        outline:
                          "2px solid " + theme.palette.background.default,
                      })}
                    >
                      <TableCell>{trx.id}</TableCell>
                      <TableCell>{trx.date}</TableCell>
                      <TableCell align="right">&#x20A6;{trx.amount}</TableCell>
                      <TableCell align="center">
                        {trx.status ? (
                          <Chip
                            variant="outlined"
                            color="success"
                            label="Paid"
                            sx={(theme) => ({
                              backgroundColor: `rgb(from ${theme.palette.success.light} r g b / 0.2)`,
                              border: "none",
                              borderRadius: "2px",
                              p: "4px",
                              height: "auto",
                            })}
                          />
                        ) : (
                          <Chip
                            variant="outlined"
                            color="error"
                            label="Unpaid"
                            sx={(theme) => ({
                              backgroundColor: `rgb(from ${theme.palette.error.light} r g b / 0.2)`,
                              border: "none",
                              borderRadius: "2px",
                              p: "4px",
                              height: "auto",
                            })}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </Box>
        </Paper>
      </Stack>
    </PageContainer>
  );
}

const StyledTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0 10px;
  min-width: 400px;
  thead th {
    color: grey;
  }
  tr td,
  tr th {
    border-bottom: none;
    padding: 10px 5px;
  }
  tr {
    // border-radius: 10px;
  }
`;

const StatLabel = styled(Box)<{color: string}>(({color}) => ({
  backgroundColor: "#fff",
  padding: "12px 15px",
  width: "100%",
  maxWidth: "250px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "10px",

  ".MuiChip-root>.MuiChip-avatar": {
    "--iconcolor": color,
    color: "var(--iconcolor)",
    backgroundColor: "rgb(from var(--iconcolor) r g b / 0.2)",
    borderRadius: "5px",
    padding: "6px",
    width: "40px",
    height: "40px",
  },
  ".MuiChip-root": {
    backgroundColor: "transparent",
  },
  "&>.MuiTypography-root": {
    fontSize: "1.3rem",
    color: "black",
  },
}));
