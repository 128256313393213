import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  styled,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PageContainer from "../components/PageContainer";
import {useEffect, useState} from "react";
import {TabPanel} from "../components/CustomComponents";
import {useAppSelector} from "../redux/store";
import {SettingsType, UserType} from "../redux/accountReducer";
import {Edit, Visibility, VisibilityOff} from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

export default function Settings() {
  const t = useSearchParams()[0].get('t')
  const [currentTab, setCurrentTab] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const {user, settings} = useAppSelector((store) => store.accountReducer);
  const [currentSettings, setCurrentSettings] = useState(settings);
  const [userDetails, setUserDetails] = useState(user);

  function handleSettingsChange(obj: Partial<SettingsType>) {
    setCurrentSettings({...currentSettings, ...obj});
  }
  function handleUserChange(obj: Partial<UserType>) {
    setUserDetails({...userDetails, ...obj});
  }
  useEffect(() => {
    if (Number(t) < 3) {
      setCurrentTab(Number(t))
    }
  },[t])
  return (
    <PageContainer title="Settings">
      <Box sx={{p: 2}}>
        <Paper
          sx={{
            boxShadow: "0 0 3px 0 rgb(0,0,0,0.1)",
            borderRadius: "5px",
            p: 4,
          }}
        >
          <h2>Settings</h2>

          <Tabs
            value={currentTab}
            onChange={(e, v) => setCurrentTab(v)}
            sx={{
              borderRadius: "5px",
              p: "5px",
              border: "1px solid",
              borderColor: "grey.200",
              width: "fit-content",
              "& .MuiTabs-indicator": {
                height: "100%",
                opacity: 0.1,
                borderRadius: "5px",
              },
              "& .MuiTab-root": {
                height: "100%",
                minHeight: "auto",
              },
            }}
          >
            <Tab label="Profile" />
            <Tab label="Change Password" />
            <Tab label="Notification" />
          </Tabs>
          <TabPanel index={0} value={currentTab}>
            <Box sx={{my: 6, display: "flex", gap: 2, alignItems: "center"}}>
              <Badge
                overlap="circular"
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                badgeContent={
                  <IconButton
                    color="primary"
                    sx={{
                      backgroundColor: "primary.main",
                      color: "primary.contrastText",
                      "&:hover": {backgroundColor: "primary.dark"},
                    }}
                  >
                    <Edit />
                  </IconButton>
                }
              >
                <Avatar
                  alt={user["First Name"]}
                  src={user.image || undefined}
                  sx={{width: 150, height: 150}}
                />
              </Badge>
              <Box sx={{fontWeight: 600, fontSize: "2rem"}}>
                {user["First Name"]} {user["Last Name"]}
              </Box>
            </Box>
            <Typography variant="h5" fontWeight={600}>
              Profile
            </Typography>
            <Typography variant="subtitle1">
              Update profile details with correct information
            </Typography>
            <Divider sx={{my: 4}} />
            <FormGroup
              sx={{
                ".MuiFormControl-root": {
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  maxWidth: "sm",
                  ".MuiInputBase-root": {
                    width: "60%",
                    border: "1px solid",
                    borderColor: "grey.300",
                    fieldset: {display: "none"},
                    "&.Mui-focused": {borderColor: "primary.light"},
                  },
                },
                gap: 3,
              }}
            >
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <OutlinedInput
                  size="small"
                  value={userDetails["First Name"]}
                  onChange={(e) =>
                    handleUserChange({"First Name": e.target.value})
                  }
                />
              </FormControl>
              <Divider />
              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <OutlinedInput
                  size="small"
                  value={userDetails["Last Name"]}
                  onChange={(e) =>
                    handleUserChange({"Last Name": e.target.value})
                  }
                />
              </FormControl>
              <Divider />
              <FormControl>
                <FormLabel>Email</FormLabel>
                <OutlinedInput
                  type="email"
                  size="small"
                  value={userDetails["Email"]}
                  onChange={(e) => handleUserChange({Email: e.target.value})}
                />
              </FormControl>
              <Divider />
              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <OutlinedInput
                  type="tel"
                  size="small"
                  value={userDetails["Phone Number"]}
                  onChange={(e) =>
                    handleUserChange({"Phone Number": e.target.value})
                  }
                />
              </FormControl>
              <div>
                <Button variant="contained">Save Changes</Button>
              </div>
            </FormGroup>
          </TabPanel>
          <TabPanel index={1} value={currentTab}>
            <Typography variant="h5" fontWeight={600}>
              Change Password
            </Typography>
            <Typography variant="subtitle1">
              Protect your account with a strong password containing letters,
              numbers and special characters
            </Typography>
            <Divider sx={{my: 4}} />
            <FormGroup
              sx={{
                ".MuiFormControl-root": {
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  maxWidth: "sm",
                  ".MuiInputBase-root": {
                    width: "60%",
                    border: "1px solid",
                    borderColor: "grey.300",
                    fieldset: {display: "none"},
                    "&.Mui-focused": {borderColor: "primary.light"},
                  },
                },
                gap: 3,
              }}
            >
              <FormControl>
                <FormLabel>Current Password</FormLabel>
                <OutlinedInput
                  size="small"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((show) => !show)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>New Password</FormLabel>
                <OutlinedInput
                  size="small"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((show) => !show)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Confirm Password</FormLabel>
                <OutlinedInput
                  size="small"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((show) => !show)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Box sx={{display: "flex", gap: 2}}>
                <Button variant="contained">Save Changes</Button>
                <Button variant="outlined">Cancel</Button>
              </Box>
            </FormGroup>
          </TabPanel>
          <TabPanel index={2} value={currentTab}>
            <Typography variant="h5" fontWeight={600}>
              Notifications Settings
            </Typography>
            <Typography variant="subtitle1">
              Enable preferred notification options
            </Typography>
            <Divider sx={{my: 4}} />
            <FormGroup
              sx={{
                ".MuiFormControl-root": {
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  maxWidth: "sm",
                },
                gap: 3,
              }}
            >
              <FormControl>
                <FormLabel>Payment Notification</FormLabel>
                <AntSwitch
                  checked={currentSettings.payment}
                  onChange={(e) =>
                    handleSettingsChange({payment: e.target.checked})
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Increased pricing plan</FormLabel>
                <AntSwitch
                  checked={currentSettings.pricing}
                  onChange={(e) =>
                    handleSettingsChange({pricing: e.target.checked})
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Recommendations</FormLabel>
                <AntSwitch
                  checked={currentSettings.recommendation}
                  onChange={(e) =>
                    handleSettingsChange({recommendation: e.target.checked})
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Transactions receiving</FormLabel>
                <AntSwitch
                  checked={currentSettings.transaction}
                  onChange={(e) =>
                    handleSettingsChange({transaction: e.target.checked})
                  }
                />
              </FormControl>
            </FormGroup>
          </TabPanel>
        </Paper>
      </Box>
    </PageContainer>
  );
}

const AntSwitch = styled(Switch)(({theme}) => ({
  width: 64,
  height: 32,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 30,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(26px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 4,
    "&.Mui-checked": {
      transform: "translateX(32px)",
      color: "#fff",
      "&  .MuiSwitch-thumb": {
        backgroundColor: "#fff",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#1890ff",
        borderColor: "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: "rgba(0,0,0,.25)",
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 32 / 2,
    opacity: 1,
    border: "1px solid rgba(0,0,0,.25)",
    backgroundColor: "transparent",
    boxSizing: "border-box",
  },
}));
