import {Description} from "@mui/icons-material";
import {
  Box,
  LinearProgress,
  LinearProgressProps,
  styled,
  SxProps,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {weekDays} from "../redux/smsReducer";
export function LinearProgressWithLabel(
  props: LinearProgressProps & {value: number}
) {
  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <Box sx={{width: "100%", mr: 1}}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{minWidth: 35}}>
        <Typography
          variant="body2"
          sx={{color: "text.secondary"}}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const filesizes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export function File({file}: {file: File}) {
  return (
    <Box sx={{display: "flex", alignItems: "center", width: "100%"}}>
      <Description color="primary" sx={{fontSize: "4rem"}} />
      <Box sx={{width: "100%"}}>
        <Typography variant="h6" fontWeight={600}>
          {file.name}
        </Typography>
        <LinearProgressWithLabel value={100} />
        <Typography variant="subtitle1">
          {filesizes(file.size)} of {filesizes(file.size)}
        </Typography>
      </Box>
    </Box>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  sx?: SxProps;
}

export function TabPanel(props: TabPanelProps) {
  const {children, value, index, sx, ...other} = props;

  return (
    <>
      {value === index && (
        <Box
          role="tabpanel"
          hidden={value !== index}
          {...other}
          sx={{py: 3, ...sx}}
        >
          {children}
        </Box>
      )}
    </>
  );
}

const DAYS = [
  {
    key: "sunday",
    label: "S",
  },
  {
    key: "monday",
    label: "M",
  },
  {
    key: "tuesday",
    label: "T",
  },
  {
    key: "wednesday",
    label: "W",
  },
  {
    key: "thursday",
    label: "T",
  },
  {
    key: "friday",
    label: "F",
  },
  {
    key: "saturday",
    label: "S",
  },
];

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
  ".MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(1),
    padding: theme.spacing(3),
    fontSize: "1.2rem",
    backgroundColor: theme.palette.background.default,
    borderRadius: "50%",
    border: "none",
  },
  ".MuiToggleButton-root": {
    "&.Mui-selected": {
      color: "white",
      background: theme.palette.primary.main,
    },
    "&:hover": {
      background: theme.palette.primary.light,
    },
    color: "inherit",
    minWidth: 32,
    maxWidth: 32,
    height: 32,
  },
}));

export const ToggleDays = ({
  days,
  setDays,
}: {
  days: weekDays[];
  setDays: (value: weekDays[]) => void;
}) => {
  return (
    <>
      <StyledToggleButtonGroup
        size="small"
        arial-label="Days of the week"
        value={days}
        onChange={(event, value) => setDays(value)}
      >
        {DAYS.map((day, index) => (
          <ToggleButton key={day.key} value={index} aria-label={day.key}>
            {day.label}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </>
  );
};
