import {createSlice} from "@reduxjs/toolkit";

const date = new Date();
const DContacts = Array(30)
  .fill("")
  .map((arr, index) => ({
    id: index,
    "First Name": "Samul",
    "Last Name": "Paris",
    Phone: "+123456789098",
    Group: 1 + index % 2,
    Date: date.toDateString(),
  }));

const DContactGroups = [
  {
    id: 1,
    Name: "branding",
    "Contacts No.": 80,
    Description: "Lorem ipsium idolo",
  },
  {
    id: 2,
    Name: "personal",
    "Contacts No.": 12,
    Description: "Lorem ipsium idolo",
  },
];

const initialState = {
  contacts: DContacts as ContactType[],
  groups: DContactGroups as ContactGroupType[],
};

const contactReducer = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setContactsState: (state, action) => {
      ({contacts: state.contacts, groups: state.groups} = action.payload);
    },
    addContact: (state, action) => {
      const index = state.contacts.findIndex((c) => c.id === action.payload.id);
      if (index >= 0) {
        state.contacts[index] = {...state.contacts[index], ...action.payload};
      } else {
        state.contacts.push(action.payload);
      }
    },
    deleteContact: (state, action) => {
      state.contacts = state.contacts.filter((c) => c.id !== action.payload.id);
    },
    addGroup: (state, action) => {
      const index = state.groups.findIndex((g) => g.id === action.payload.id);
      if (index >= 0) {
        state.groups[index] = {...state.groups[index], ...action.payload};
      } else {
        state.groups.push(action.payload);
      }
    },
    deleteGroup: (state, action) => {
      state.groups = state.groups.filter((g) => g.id !== action.payload.id);
    },
  },
});

export default contactReducer.reducer;
export const {
  setContactsState,
  addContact,
  deleteContact,
  addGroup,
  deleteGroup,
} = contactReducer.actions;

export type ContactType = {
  id: number;
  "First Name": string;
  "Last Name": string;
  Phone: string;
  Group: ContactGroupType["id"];
  Date: string;
};

export type ContactGroupType = {
  id: number;
  Name: string;
  "Contacts No.": number;
  Description: string;
};
