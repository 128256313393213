import {MoreHoriz} from "@mui/icons-material";
import {Avatar, Box, SxProps, Typography} from "@mui/material";

export default function Logo({sx}: {sx?: SxProps}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      <Typography variant="h3" component={"h1"} sx={{fontWeight: 700}}>
        ETC
      </Typography>
      <Avatar
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          width: "30px",
          height: "30px",
        })}
      >
        <MoreHoriz />
      </Avatar>
    </Box>
  );
}
