import {useEffect, useState} from "react";
import {useAppSelector} from "../../redux/store";
import {ScheduledSmsType} from "../../redux/smsReducer";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  InputBase,
  List,
  ListItem,
  Stack,
  TablePagination,
  Toolbar,
  Typography,
} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import {
  Add,
  CheckBoxOutlined,
  Close,
  DeleteOutline,
  DeleteOutlined,
  DriveFileRenameOutline,
} from "@mui/icons-material";
import Sort from "../../components/Sort";
import DeleteDialog from "../../components/DeleteDialog";
import Sms from "../../components/Sms";

type TDialog = {
  open: boolean;
  schedule?: ScheduledSmsType;
};
export default function ScheduleSms() {
  const {schedulesSms} = useAppSelector((store) => store.smsReducer);
  const [editDialog, setEditDialog] = useState<TDialog>({open: false});
  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    element: any;
    label: string;
  }>({open: false, label: " ", element: null});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState<{[key: number]: boolean}>({});

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSelect(index: number) {
    const state = {...selected};
    if (state[index]) {
      delete state[index];
    } else {
      state[index] = true;
    }
    setSelected(state);
  }

  function handleDelete(element: ScheduledSmsType | string[]) {
    console.log(element);
    setDeleteDialog({open: false, label: "", element: null});
  }
  return (
    <>
      <Toolbar sx={{px: {sm: 0}, justifyContent: "space-between"}}>
        <div>
          <Typography
            variant="h3"
            fontSize={"1.7rem"}
            fontWeight={600}
            marginBottom={1}
          >
            Scheduled Messages
          </Typography>
          <Typography variant="subtitle1">
            Here you can create new texts and edit the settings of previously
            scheduled texts.
          </Typography>
        </div>

        {Object.keys(selected).length < 1 ? (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "flex-end",
            }}
          >
            <SearchBar width="60%" />
            <Sort />
            <Button
              startIcon={<Add />}
              variant="contained"
              onClick={() => setEditDialog({open: true})}
            >
              Schedule
            </Button>
          </Box>
        ) : (
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteOutlined />}
            size="large"
            onClick={() =>
              setDeleteDialog({
                label: "scheduled messages",
                open: true,
                element: Object.keys(selected),
              })
            }
          >
            Delete
          </Button>
        )}
      </Toolbar>
      <List
        sx={{
          "li>.MuiBox-root": {
            position: "relative",
            borderBottom: "1px solid",
            borderColor: "grey.200",
            borderRadius: "10px",
            pb: 2,
            px: 2,
            width: "100%",
            "&:hover": {
              backgroundColor: "background.default",
              ".MuiButtonGroup-root": {display: "inline-flex"},
            },
          },
          "li .MuiButtonGroup-root": {
            position: "absolute",
            right: 15,
            top: 15,
            display: "none",
          },
        }}
      >
        {schedulesSms.map(
          (schedule, index) =>
            index < (page + 1) * rowsPerPage &&
            index >= page * rowsPerPage && (
              <ListItem key={schedule.id}>
                <Checkbox
                  onClick={() => handleSelect(schedule.id)}
                  checked={!!selected[schedule.id]}
                  onChange={() => {}}
                  checkedIcon={<CheckBoxOutlined />}
                  sx={{color: "grey.200"}}
                />
                <Box>
                  <Typography
                    sx={{
                      "&>span": {color: "primary.main"},
                      fontWeight: 500,
                      fontSize: "large",
                      my: 2,
                    }}
                  >
                    <span>TO: </span>
                    {JSON.parse(schedule.to)}
                  </Typography>
                  <Typography variant="subtitle1">
                    {schedule.message}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    marginTop={2}
                    color="grey.400"
                    textAlign="right"
                  >
                    Scheduled at: {schedule.time}, {schedule.date}{" "}
                  </Typography>
                  <ButtonGroup>
                    <IconButton
                      onClick={() => setEditDialog({open: true, schedule})}
                    >
                      <DriveFileRenameOutline />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() =>
                        setDeleteDialog({
                          open: true,
                          element: schedule,
                          label: `scheduled message`,
                        })
                      }
                    >
                      <DeleteOutline />
                    </IconButton>
                  </ButtonGroup>
                </Box>
              </ListItem>
            )
        )}
      </List>
      <TablePagination
        component="div"
        count={schedulesSms.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <EditDialog
        open={editDialog.open}
        schedule={editDialog.schedule}
        onClose={() => setEditDialog({open: false})}
      />
      <DeleteDialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({...deleteDialog, open: false})}
        handleDelete={handleDelete}
        label={deleteDialog.label}
        element={deleteDialog.element}
      />
    </>
  );
}

function EditDialog({
  open,
  onClose,
  schedule,
}: {
  open: boolean;
  onClose: () => void;
  schedule?: ScheduledSmsType;
}) {
  const [scheduleData, setScheduleData] = useState<ScheduledSmsType>(
    {} as ScheduledSmsType
  );
  useEffect(() => {
    if (schedule) {
      setScheduleData(schedule);
    } else {
      setScheduleData({} as ScheduledSmsType);
    }
  }, [schedule]);

  function handleScheduleChange(obj: Partial<ScheduledSmsType>) {
    setScheduleData({...scheduleData, ...obj});
  }

  return (
    <Dialog open={open} maxWidth="xl" onClose={onClose}>
      <DialogTitle position="relative" fontWeight={600} variant="h4">
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "30%",
            right: 7,
            border: "1px solid",
          }}
          size="small"
        >
          <Close />
        </IconButton>
        {schedule ? "Edit" : "New"} Schedule
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            my: 3,
            ">.MuiFormControl-root": {
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              my: 2,
              ">.MuiFormLabel-root": {fontWeight: 700},
              ".MuiInputBase-root": {
                border: "1px solid",
                borderRadius: "5px",
                borderColor: "grey.400",
                p: 1,
                minWidth: "85%",
                outline: "none",
                "*::placeholder": {color: "grey.500"},
                "&.Mui-focused": {borderColor: "primary.light"},
              },
              ">.MuiAutocomplete-root": {
                width: "85%",
                ">.MuiInputBase-root": {width: "100%"},
              },
            },
          }}
        >
          <Sms handleSmsChange={handleScheduleChange} sms={scheduleData} />
          <Stack
            sx={{
              gap: 2,
              ".MuiInputBase-root": {
                maxWidth: "300px",
                backgroundColor: "background.default",
                p: 1,
                borderRadius: "5px",
                fontWeight: 600,
              },
            }}
          >
            <b>Schedule for later</b>
            <FormControl>
              <FormLabel>Select Date</FormLabel>
              <InputBase
                type="date"
                onChange={(e) => handleScheduleChange({date: e.target.value})}
                value={scheduleData.date || ""}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Select Time</FormLabel>
              <InputBase
                type="time"
                onChange={(e) => handleScheduleChange({time: e.target.value})}
                value={scheduleData.time || ""}
              />
            </FormControl>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="large" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" size="large">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
