import {
  BookmarkRemoveOutlined,
  HighlightOff,
  Portrait,
} from "@mui/icons-material";
import {
  Autocomplete,
  autocompleteClasses,
  AutocompleteRenderGroupParams,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputBase,
  List,
  ListSubheader,
  Radio,
  RadioGroup,
} from "@mui/material";
import {useEffect, useState} from "react";
import {useAppSelector} from "../redux/store";
import {SmsTemplateType, SmsType} from "../redux/smsReducer";
import {ContactType} from "../redux/contactReducer";

export default function Sms({
  handleSmsChange,
  sms,
}: {
  handleSmsChange: (sms: Partial<SmsType>) => void;
  sms: SmsType;
}) {
  const {contacts, groups} = useAppSelector((store) => store.contactReducer);
  const [selContacts, setSelContacts] = useState<(ContactType | string)[]>([]);

  function selectAllGroup(group: string, children: any[]) {
    const groupIds = groups.filter((g) => g.Name === group).map((g) => g.id);
    let newcontacts = selContacts;
    newcontacts = newcontacts.filter(
      (c) => typeof c === "string" || !groupIds.includes(c.Group)
    );
    if (!children.every((child) => child.props["aria-selected"])) {
      newcontacts = [
        ...newcontacts,
        ...children
          .map((child) => Number(child.key))
          .map((key) => contacts[key]),
      ];
    }
    handleInputChange({to: JSON.stringify(newcontacts)});
  }

  function renderGroup(params: AutocompleteRenderGroupParams) {
    const {key, group, children} = params;

    return (
      <div key={key}>
        <ListSubheader
          className={autocompleteClasses.groupLabel}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            top: "-10px",
          }}
        >
          {group}
          <Checkbox
            checked={
              Array.isArray(children) &&
              children.every((child) => child.props["aria-selected"])
            }
            onChange={(e) => selectAllGroup(group, children as any[])}
          />
        </ListSubheader>
        <List className={autocompleteClasses.groupUl}>{children}</List>
      </div>
    );
  }
  function handleInputChange(smsData: Partial<SmsType>) {
    handleSmsChange({...sms, ...smsData});
  }

  useEffect(() => {
    sms.to && setSelContacts(JSON.parse(sms.to));
  }, [sms.to]);

  return (
    <Box
      sx={{
        ">.MuiFormControl-root": {
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          my: 2,
          ">.MuiFormLabel-root": {fontWeight: 700},
          ".MuiInputBase-root": {
            border: "1px solid",
            borderRadius: "5px",
            borderColor: "grey.400",
            p: 1,
            minWidth: "85%",
            outline: "none",
            "*::placeholder": {color: "grey.500"},
            "&.Mui-focused": {borderColor: "primary.light"},
          },
          ">.MuiAutocomplete-root": {
            width: "85%",
            ">.MuiInputBase-root": {width: "100%"},
          },
        },
      }}
    >
      <FormControl>
        <FormLabel>To:</FormLabel>
        <Autocomplete
          onChange={(e, v) => handleInputChange({to: JSON.stringify(v)})}
          value={selContacts}
          multiple
          limitTags={2}
          freeSolo
          options={[...contacts].sort((a, b) => a.Group - b.Group)}
          filterOptions={(options, state) =>
            options.filter((option) =>
              Object.values(option).some((value) =>
                value
                  .toString()
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              )
            )
          }
          groupBy={(option: ContactType) =>
            groups.find((group) => group.id === option.Group)?.Name || ""
          }
          getOptionLabel={(option) =>
            typeof option === "string"
              ? option
              : `${option["First Name"]} ${option["Last Name"]}`
          }
          getOptionKey={(option) =>
            typeof option === "string" ? option : option.id
          }
          renderGroup={(params) => renderGroup(params)}
          renderOption={(props, option: ContactType, {selected}) => {
            const {key, ...optionProps} = props;
            return (
              <li key={key} {...optionProps}>
                <Checkbox checked={selected} />
                {`${option["First Name"]} ${option["Last Name"]}`}
              </li>
            );
          }}
          renderInput={(params) => {
            return (
              <InputBase
                {...params.InputProps}
                inputProps={params.inputProps}
                placeholder="Enter numbers or search contacts / Groups"
              />
            );
          }}
          renderTags={(value: readonly ContactType[], getTagProps) =>
            value.map((option: string | ContactType, index: number) => {
              const {key, ...tagProps} = getTagProps({index});
              return (
                <Chip
                  variant="outlined"
                  label={
                    typeof option === "string"
                      ? option.slice(0, 6)
                      : option["First Name"]
                  }
                  key={key}
                  {...tagProps}
                />
              );
            })
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel>From:</FormLabel>
        <InputBase
          value={sms.from || ''}
          onChange={(e) => handleInputChange({from: e.target.value})}
          placeholder="Company/Business name"
        />
      </FormControl>
      <FormControl>
        <FormLabel>Message:</FormLabel>
        <Box sx={{width: "85%", display: "flex", flexDirection: "column"}}>
          <InputBase
            multiline
            minRows={7}
            placeholder="New message..."
            value={sms.message || ''}
            onChange={(e) => handleInputChange({message: e.target.value})}
            endAdornment={
              <ControlButtons
                message={sms.message || ""}
                setMessage={(message) => handleInputChange({message})}
              />
            }
            slotProps={{
              root: {sx: {display: "flex", flexDirection: "column"}},
            }}
          />
          <FormHelperText sx={{color: "burlywood", mx: 0}}>
            The message will be sent to the exact audience
          </FormHelperText>
        </Box>
      </FormControl>
    </Box>
  );
}

function ControlButtons({
  message,
  setMessage,
}: {
  message: string;
  setMessage: (message: string) => void;
}) {
  const [openPersonalize, setOpenPersonalized] = useState(false);
  const [selRadio, setSelRadio] = useState("First Name");
  const [openTemplate, setOpenTemplate] = useState(false);
  const templates = useAppSelector((store) => store.smsReducer.templates);

  function handlePersonalized() {
    setMessage(message + `{{${selRadio}}}`);
    setOpenPersonalized(false);
  }
  function handleTemplate(template: SmsTemplateType) {
    setMessage(template.message);
    setOpenTemplate(false);
  }
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        ">button": {
          flexDirection: "column",
          fontSize: "0.7rem",
          color: "grey.400",
          borderRadius: "0",
          px: 2,
          "&:not(:last-child)": {
            borderRight: "1px solid",
            borderColor: "grey.300",
          },
          "&:hover": {backgroundColor: "transparent", color: "grey.500"},
        },
      }}
    >
      <IconButton onClick={() => setOpenPersonalized(true)}>
        <Portrait />
        Personalized
      </IconButton>
      <Dialog
        open={openPersonalize}
        onClose={() => setOpenPersonalized(false)}
        fullWidth
      >
        <DialogTitle
          sx={{
            backgroundColor: "rgba(25, 118, 210, 0.2)",
            display: "flex",
            gap: 3,
            justifyContent: "space-between",
          }}
        >
          Personalize Your Message
          <IconButton size="small" onClick={() => setOpenPersonalized(false)}>
            <HighlightOff fontSize="large" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Increase engagement by personalizing texts with information from
            contact records. Simply select a contact field below and we'll
            include it in your message. Please note, each contact field may use
            up to 20 characters of your 160 SMS limit.
          </DialogContentText>
          <FormControl sx={{mt: 3}}>
            <FormLabel sx={{fontWeight: 700, fontSize: "large"}}>
              Select Contact Field
            </FormLabel>
            <RadioGroup
              onChange={(e) => setSelRadio(e.target.value)}
              value={selRadio}
            >
              <FormControlLabel
                value="First Name"
                control={<Radio />}
                label="First name"
              />
              <FormControlLabel
                value="Last Name"
                control={<Radio />}
                label="Last name"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handlePersonalized}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton onClick={() => setOpenTemplate(true)}>
        <BookmarkRemoveOutlined />
        Template
      </IconButton>
      <Dialog
        open={openTemplate}
        onClose={() => setOpenTemplate(false)}
        fullWidth
      >
        <DialogTitle
          sx={{
            backgroundColor: "rgba(25, 118, 210, 0.2)",
            display: "flex",
            gap: 3,
            justifyContent: "space-between",
            fontWeight: "bold",
          }}
        >
          Select Message Template
          <IconButton size="small" onClick={() => setOpenTemplate(false)}>
            <HighlightOff fontSize="large" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{display: "flex", flexDirection: "column", gap: 3, my: 4}}
        >
          {templates.map((template) => (
            <Card key={template.id} variant="outlined">
              <CardHeader
                title={template.title}
                action={
                  <Button
                    variant="outlined"
                    onClick={() => handleTemplate(template)}
                  >
                    Use
                  </Button>
                }
              />
              <CardContent sx={{py: 0, fontSize: "small"}}>
                {template.message}
              </CardContent>
            </Card>
          ))}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
